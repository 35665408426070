import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Card, CardMedia, CardContent, Typography, IconButton } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import TopNavigation from './TopNavigation';
import SideNavigation from './SideNavigation';
import BottomNavigationBar from './BottomNavigationBar';
import HamburgerMenu from './HamburgerMenu';  // Import your hamburger menu component

function ProductList() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const vendorId = localStorage.getItem('vendor_id');

  const loadMenuItems = async (categoryId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/Product/Category/${vendorId}/${categoryId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const productsData = await response.json();
      console.log({ productsData })
      setProducts(productsData.products);
    } catch (error) {
      console.error('Failed to load products:', error);
    }
  };

  const handleSearch = (searchTerm) => {
    const filtered = products.filter(product =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  const addToCart = (product) => {
    const existingCartItems = JSON.parse(localStorage.getItem('cart_items')) || [];
    const existingProductIndex = existingCartItems.findIndex(item => item.id === product.id);

    if (existingProductIndex >= 0) {
      existingCartItems[existingProductIndex].quantity += 1;
      existingCartItems[existingProductIndex].totalAmount += parseFloat(product.price);
    } else {
      existingCartItems.push({
        ...product,
        quantity: 1,
        totalAmount: parseFloat(product.price)
      });
    }

    localStorage.setItem('cart_items', JSON.stringify(existingCartItems));
    alert(`Added ${product.name} to cart!`);
  };

  return (
    <div className="app-container" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        style={{ display: 'flex', alignItems: 'center', padding: '10px 20px' }}
      >
        <TopNavigation vendorId={vendorId} onSearch={handleSearch} style={{ flexGrow: 1 }} /> {/* Flex-grow to push the hamburger to the right */}
        <HamburgerMenu />
      </Box>

      <div className="content-container" style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        <div className="side-nav" style={{ width: 80, flexShrink: 0, backgroundColor: '#fff' }}>
          <SideNavigation vendorId={vendorId} onCategorySelect={loadMenuItems} />
        </div>
        <div className="main-content" style={{ flexGrow: 1, padding: '0 10px', overflowY: 'auto' }}>
          <Box p={1} display="flex" flexWrap="wrap" gap={2}>
            {products.length === 0 ? (
              <Typography variant="body1" color="textSecondary">
                No products available. Please select a category or search for products.
              </Typography>
            ) : (
              products.map(product => (
                <Card
                  key={product.id}
                  component={Link}
                  to={`/product/${vendorId}/${product.id}`}
                  style={{
                    textDecoration: 'none',
                    position: 'relative',
                    paddingBottom: '10px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    maxWidth: '100px'
                  }}
                >
                  {product.image_path ? (
                    <CardMedia
                      component="img"
                      image={product.image_path}
                      alt={product.name}
                      crossOrigin="anonymous"
                      style={{
                        width: '100px',
                        height: '50%',
                        objectFit: 'cover',
                        marginRight: '10px'
                      }}
                    />
                  ) : null}

                  <CardContent>
                    <Typography
                      variant="body2"
                      style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                      }}
                    >
                      {product.name}
                    </Typography>
                    <Typography variant="body2">RM {Number(product.price).toFixed(2)}</Typography>
                  </CardContent>

                  <IconButton
                    aria-label="add to cart"
                    style={{
                      position: 'absolute',
                      bottom: '-2px',
                      right: '0px',
                      backgroundColor: 'rgba(255, 255, 255, 0.7)'
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      addToCart(product);
                    }}
                  >
                    <AddShoppingCartIcon />
                  </IconButton>
                </Card>
              ))
            )}
          </Box>
        </div>
      </div>
    </div>
  );
}

export default ProductList;
