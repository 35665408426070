import React from 'react';
import { Box, Typography, Button } from '@mui/material';

function WebhooksSettings() {
  return (
    <Box p={3}>
      <Typography variant="h4" mb={2}>Manage webhook</Typography>
      <Button variant="contained" color="primary">Add New webhook</Button>
    </Box>
  );
}

export default WebhooksSettings;
