import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, List, ListItem, ListItemText, Divider, Button, IconButton, Card, CardContent } from '@mui/material';
import BottomNavigationBar from './BottomNavigationBar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HamburgerMenu from './HamburgerMenu';  // Import HamburgerMenu component

function OrderDetail() {
  const { orderId } = useParams();  // Get the orderId from URL params
  const [order, setOrder] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const token = localStorage.getItem('token');
      const vendorId = localStorage.getItem('vendor_id');

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/Pos/Order/${vendorId}/${orderId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const orderData = await response.json();
        console.log({ orderData });
        setOrder(orderData);  // Set order details
      } catch (error) {
        console.error('Failed to load order details:', error);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const handleCancelOrder = async () => {
    const confirmCancel = window.confirm('Are you sure you want to cancel this order?');
    if (confirmCancel) {
      const vendorId = localStorage.getItem('vendor_id');
      const token = localStorage.getItem('token');

      const orderData = {
        orderId,
        status: 'cancel'
      };

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/Pos/Order/Status/${vendorId}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(orderData)
        });

        if (response.ok) {
          alert('Order cancelled successfully');
          navigate('/order');
        } else {
          alert('Failed to cancel the order');
        }
      } catch (error) {
        console.error('Error cancelling order:', error);
        alert('An error occurred while cancelling the order');
      }
    }
  };

  const handleRefundOrder = () => {
    const confirmRefund = window.confirm('Are you sure you want to refund this order?');
    if (confirmRefund) {
      alert('Order refunded!');
      // You can add your refund order logic here
    }
  };

  const handleCheckoutOrder = () => {
    navigate(`/checkout?orderId=${orderId}`);
  };

  const handlePrintReceipt = () => {
    navigate(`/print-receipt?orderId=${orderId}`);
  };

  const renderButtons = (status) => {
    switch (status) {
      case 'paid':
        return (
          <Box display="flex" gap={2}>
            {/* <Button variant="contained" color="error" onClick={handleCancelOrder}>Cancel</Button> */}
            <Button variant="contained" color="warning" onClick={handleRefundOrder}>Refund</Button>
            <Button variant="contained" color="primary" onClick={handlePrintReceipt}>Receipt</Button>
            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>
              Back
            </Button>
          </Box>
        );
      case 'completed':
      case 'pending':
        return (
          <Box display="flex" gap={2}>
            <Button variant="contained" color="error" onClick={handleCancelOrder}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={handleCheckoutOrder}>Checkout</Button>
            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>
              Back
            </Button>
          </Box>
        );
      case 'cancel':
      case 'refund':
        return (
          <Box display="flex" gap={2}>
            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>
              Back
            </Button>
          </Box>
        );
      default:
        return null;
    }
  };

  if (!order) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <HamburgerMenu />

      <Box p={2} mt={0} style={{ flexGrow: 1 }}>

        <Typography variant="h5" mb={2}>Order Detail</Typography>
        <Card elevation={2} sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="body1"><strong>Order ID:</strong> {order.id}</Typography>
            <Typography variant="body1"><strong>Order Type:</strong> {order.order_type}</Typography>
            <Typography variant="body1"><strong>Status:</strong> {order.status}</Typography>
          </CardContent>
        </Card>

        <Typography variant="h6" mt={2}>Items:</Typography>
        <List>
          {order.items.map(item => (
            <React.Fragment key={item.id}>
              <ListItem>
                <ListItemText
                  primary={item.name}
                  secondary={
                    <>
                      <Typography variant="body2">Quantity: {item.quantity}</Typography>
                      <Typography variant="body2">Price: RM {item.price}</Typography>

                      {/* Display Add-ons */}
                      {item.add_ons && (
                        <Typography variant="body2">Add-On: {item.add_ons}</Typography>
                      )}

                      {/* Display Toppings */}
                      {item.toppings && item.toppings.length > 0 && (
                        <Typography variant="body2">Toppings: {item.toppings}</Typography>
                      )}

                      {/* Display Specifications */}
                      {item.specifications && item.specifications.length > 0 && (
                        <Typography variant="body2">
                          Specifications:{" "}
                          {item.specifications.map((spec, index) => (
                            <React.Fragment key={index}>
                              {spec.name}:{" "}
                              {spec.options.map((option, i) => (
                                <span key={`${option.option_name}-${i}`}>
                                  {option.option_name} (+RM {option.extra_cost})
                                  {i < spec.options.length - 1 && ", "}
                                </span>
                              ))}
                              {index < item.specifications.length - 1 && ", "}
                            </React.Fragment>
                          ))}
                        </Typography>
                      )}
                    </>
                  }
                />
                <Typography variant="body2" fontWeight="bold">
                  Total: RM {Number(item.total_price).toFixed(2)}
                </Typography>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>

        <Typography variant="h6" mt={2}>Order Summary</Typography>
        <Card elevation={2} sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="body1"><strong>Subtotal:</strong> RM {Number(order.receivable).toFixed(2)}</Typography>
            <Typography variant="body1"><strong>Taxes:</strong> RM {Number(order.total_taxes).toFixed(2)}</Typography>
            {order.service_charges && (
              <Typography variant="body1">
                <strong>Service Charges:</strong> {JSON.parse(order.service_charges)
                  .map(charge => `${charge.name} ${charge.type === 'percentage' ? `${charge.taxAmount}%` : `RM ${charge.taxAmount}`}`)
                  .join(', ')}
              </Typography>
            )}
            <Typography variant="body1"><strong>Total Payable:</strong> RM {Number(order.rounded_receivable).toFixed(2)}</Typography>
            <Typography variant="body1"><strong>Amount Paid:</strong> RM {Number(order.received_amount).toFixed(2)}</Typography>
            <Typography variant="body1"><strong>Change:</strong> RM {Number(order.change).toFixed(2)}</Typography>
          </CardContent>
        </Card>

        {/* Render action buttons based on status */}
        <Box mt={4}>
          {renderButtons(order.status)}
        </Box>
      </Box>

      {/* <Box style={{ position: 'sticky', bottom: 0, left: 0, width: '100%', backgroundColor: '#fff', boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)' }}>
        <BottomNavigationBar />
      </Box> */}
    </Box>
  );
}

export default OrderDetail;
