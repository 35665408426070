import React from 'react';
import { Box, Typography, Button } from '@mui/material';

function ProductSettings() {
  return (
    <Box p={3}>
      <Typography variant="h4" mb={2}>Manage Product</Typography>
      <Button variant="contained" color="primary">Add New Product</Button>
    </Box>
  );
}

export default ProductSettings;
