import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import { LocalCafe, Fastfood, EmojiEvents, Icecream, AcUnit, LunchDining } from '@mui/icons-material';
function SideNavigation({ vendorId, onCategorySelect }) {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const fetchCategories = async () => {
      try {
        if (vendorId > 0) {

          const response = await fetch(`${process.env.REACT_APP_API_URL}/Categorie/List/${vendorId}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const categoriesData = await response.json();
          console.log({ categoriesData })
          setCategories(categoriesData);
        }
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };

    fetchCategories();
  }, [vendorId]);

  useEffect(() => {
    if (categories.length > 0 && !selectedCategory) {
      const firstCategoryId = categories[0].id;
      setSelectedCategory(firstCategoryId);
      onCategorySelect(firstCategoryId);  // Trigger first category
    }
  }, [categories, selectedCategory, onCategorySelect]);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    onCategorySelect(categoryId);
  };

  const categoryIcons = {
    beverage: <LocalCafe />,
    breakfast: <Fastfood />,
    top: <EmojiEvents />,
    lunch: <LunchDining />,
    'set lunch': <LunchDining />,
    'ice-cream': <Icecream />,
    defaultIcon: <AcUnit />
  };

  return (
    <List component="nav" style={{ width: '100%', backgroundColor: '#fff', height: '100vh' }}>
      {categories.map(category => (
        <ListItem
          button
          key={category.id}
          onClick={() => handleCategoryClick(category.id)}
          selected={category.id === selectedCategory}  //selected category
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <ListItemIcon style={{ minWidth: 'auto', marginBottom: '2px' }}>
              {categoryIcons[category.name.toLowerCase()] || categoryIcons.defaultIcon}
            </ListItemIcon>
            <ListItemText primary={category.name} style={{ textAlign: 'center' }} />
          </Box>
        </ListItem>
      ))}
    </List>
  );
}

export default SideNavigation;
