import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, IconButton, TextField, Divider } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import BottomNavigationBar from './BottomNavigationBar';
import HamburgerMenu from './HamburgerMenu';  // Import HamburgerMenu component

function CheckoutPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [orderDetails, setOrderDetails] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [change, setChange] = useState(0);
  const [roundedReceivable, setRoundedReceivable] = useState(0);
  const [roundingValue, setRoundingValue] = useState(0);
  const [vendorId] = useState(localStorage.getItem('vendor_id'));
  const [token] = useState(localStorage.getItem('token'));
  const [totalTaxes, setTotalTaxes] = useState(0);
  const [serviceCharges, setServiceCharges] = useState([]);
  const [receivable, setReceivable] = useState(0);

  const orderId = new URLSearchParams(location.search).get('orderId');

  useEffect(() => {
    if (orderId && vendorId) {
      loadOrderDetails();
      loadPaymentMethods();
    }
  }, [orderId, vendorId]);

  const loadOrderDetails = async () => {
    try {
      const orderResponse = await fetch(`${process.env.REACT_APP_API_URL}/Pos/Order/${vendorId}/${orderId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const order = await orderResponse.json();

      const vendorTaxes = await fetchVendorTaxes(vendorId);
      const amountToPay = Number(order.amount_to_pay).toFixed(2);

      let calculatedTaxes = 0;
      let charges = [];

      vendorTaxes.forEach(tax => {
        const taxValue = tax.type === 'percentage' ? (amountToPay * tax.taxAmount) / 100 : parseFloat(tax.taxAmount);
        calculatedTaxes += taxValue;
        charges.push({ name: tax.name, type: tax.type, taxAmount: tax.taxAmount });
      });

      const receivable = parseFloat(amountToPay) + calculatedTaxes;
      const rounded = customRound(receivable);
      setReceivable(receivable);
      setRoundedReceivable(rounded);
      setRoundingValue(rounded - receivable);

      setTotalTaxes(calculatedTaxes);
      setServiceCharges(charges);
      setOrderDetails(order);
    } catch (error) {
      console.error('Error loading order details:', error);
    }
  };

  async function fetchVendorTaxes(vendorId) {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/VendorTaxes/WithTaxTypes/${vendorId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return await response.json();
  }

  const loadPaymentMethods = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/PaymentMethod/List/${vendorId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const methods = await response.json();
      setPaymentMethods(methods);
    } catch (error) {
      console.error('Error loading payment methods:', error);
    }
  };

  const handleCheckout = async () => {
    if (!selectedPaymentMethod) {
      alert('Please select a payment method.');
      return;
    }

    if (receivedAmount < roundedReceivable) {
      alert('Received amount is less than the receivable amount.');
      return;
    }

    const paymentData = {
      orderId,
      paymentType: selectedPaymentMethod,
      status: 'paid',
      amountToPay: orderDetails.amount_to_pay,
      receivable,
      roundedReceivable,
      receivedAmount,
      change,
      serviceCharge: serviceCharges,
      totalTaxes,
      roundingValue,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/Pos/Payments/${vendorId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(paymentData),
      });

      const result = await response.json();
      if (result) {
        alert('Payment processed successfully');
        navigate(`/print-receipt?orderId=${orderId}`);
      } else {
        alert('Failed to process payment: ' + result.message);
      }
    } catch (error) {
      console.error('Error processing payment:', error);
    }
  };

  const customRound = value => {
    const fractionPart = value - Math.floor(value);
    const decimalPart = (fractionPart % 0.1).toFixed(2);
    return decimalPart < 0.05 ? Math.floor(value * 20) / 20 : Math.ceil(value * 20) / 20;
  };

  const addAmount = amount => {
    const updatedAmount = parseFloat(receivedAmount) + amount;
    setReceivedAmount(updatedAmount);
    setChange(updatedAmount - roundedReceivable);
  };

  const clearAmount = () => {
    setReceivedAmount(0);
    setChange(0);
  };

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <HamburgerMenu />

      <Box p={2} sx={{ flex: 1, overflowY: 'auto' }}>
        <Typography variant="h6" mb={2}>Checkout</Typography>

        {orderDetails && (
          <Box>
            <Typography variant="body1">Order Total: RM {Number(orderDetails.amount_to_pay).toFixed(2)}</Typography>

            {/* Replace Total Taxes with Individual Taxes */}
            {serviceCharges.length > 0 && (
              <Box>
                {serviceCharges.map((tax, index) => (
                  <Typography key={index} variant="body1">
                    Total Taxes ({tax.name}: {tax.type === 'percentage' ? `${tax.taxAmount}%` : `RM ${tax.taxAmount}`}) : RM {Number(totalTaxes).toFixed(2)}
                  </Typography>
                ))}
              </Box>
            )}

            <Typography variant="body1">Receivable: RM {Number(receivable).toFixed(2)}</Typography>

            <Typography variant="body1">Rounding: RM {roundingValue.toFixed(2)}</Typography>
            <Typography
              variant="body1"
              sx={{
                backgroundColor: 'yellow',  // You can change the color to any valid CSS color
                borderRadius: '4px',        // Optional: adds rounded corners
                fontWeight: 'bold',
              }}
            >
              Rounded Receivable: RM {roundedReceivable.toFixed(2)}
            </Typography>

            <Divider sx={{ my: 2 }} />

            <Typography variant="h6" mb={1}>Select Payment Method:</Typography>
            <Box display="flex" flexWrap="wrap" gap={2}>
              {paymentMethods.map(method => (
                <Button
                  key={method.id}
                  variant={selectedPaymentMethod === method.name ? 'contained' : 'outlined'}
                  onClick={() => setSelectedPaymentMethod(method.name)}
                  sx={{ mb: 1 }}
                >
                  {method.name}
                </Button>
              ))}
            </Box>

            <Typography variant="h6" mb={1}>Quick Amounts</Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {[5, 10, 20, 50, 100, 200].map(amount => (
                <Button key={amount} variant="outlined" onClick={() => addAmount(amount)}>
                  + RM {amount.toFixed(2)}
                </Button>
              ))}
              <Button variant="outlined" onClick={clearAmount}>Clear</Button>
            </Box>

            <TextField
              label="Received Amount"
              type="number"
              value={receivedAmount}
              onChange={e => setReceivedAmount(parseFloat(e.target.value))}
              fullWidth
              sx={{ mt: 2, mb: 2 }}
            />
            <Typography variant="body1" sx={{ color: 'red', fontWeight: 'bold' }}>
              Change: RM {change.toFixed(2)}
            </Typography>
            <Divider sx={{ my: 2 }} />


            <Box display="flex" gap={2}>
              <Button variant="contained" color="primary" onClick={handleCheckout}>
                Confirm Payment
              </Button>

              {/* Cash Box Button */}
              <Button variant="outlined" color="secondary" onClick={() => alert('Cash Box opened!')}>
                Cash Box
              </Button>

              {/* Back Button */}
              <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>
                Back
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      {/* <Box sx={{ position: 'sticky', bottom: 0, left: 0, width: '100%' }}>
        <BottomNavigationBar />
      </Box> */}
    </Box>
  );
}

export default CheckoutPage;
