import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

const HamburgerMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const userRole = localStorage.getItem('role'); // Fetch the role from localStorage

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path) => {
    handleMenuClose();
    navigate(path);
  };

  return (
    <>
      <IconButton
        edge="end"
        color="inherit"
        aria-label="menu"
        onClick={handleMenuOpen}
        style={{ position: 'absolute', top: '10px', right: '10px' }}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleMenuItemClick('/home')}>Home</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('/cart')}>Cart</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('/order')}>Order</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('/profile')}>Profile</MenuItem>

        {/* Render the Settings option based on user role */}
        {(userRole === 'super_admin' || userRole === 'admin' || userRole === 'manager') && (
          <MenuItem onClick={() => handleMenuItemClick('/settings')}>Settings</MenuItem>
        )}

        <MenuItem onClick={() => handleMenuItemClick('/logout')}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default HamburgerMenu;
