import React from 'react';
import { Box, Typography, Button } from '@mui/material';

function TaxesSettings() {
  return (
    <Box p={3}>
      <Typography variant="h4" mb={2}>Manage Taxes</Typography>
      <Button variant="contained" color="primary">Add New Taxes</Button>
    </Box>
  );
}

export default TaxesSettings;
