import React, { useState } from 'react';
import { AppBar, Toolbar, InputBase, IconButton, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function CustomerTopNavigation({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  return (
    <AppBar position="static" style={{ backgroundColor: '#f8f9fa', boxShadow: 'none' }}>
      <Toolbar>
        <InputBase
          placeholder="Search for products..."
          value={searchTerm}
          onChange={handleSearchInputChange}
          style={{ backgroundColor: '#f1f1f1', padding: '5px 10px', borderRadius: '4px', marginRight: '10px', width: '100%' }}
        />
        <IconButton color="primary" onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default CustomerTopNavigation;
