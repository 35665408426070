import React, { useState } from 'react';
import { Button, Box, Typography, Tabs, Tab, BottomNavigation, BottomNavigationAction } from '@mui/material';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ReportIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate, useLocation } from 'react-router-dom';

const TableOrderScreen = () => {
  const [selectedOrderType, setSelectedOrderType] = useState('Dine-In');
  const [selectedLocation, setSelectedLocation] = useState('Hall');
  const [selectedTable, setSelectedTable] = useState(null);
  const [occupiedTables, setOccupiedTables] = useState([]);
  const [navigationValue, setNavigationValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const markTableAsOccupied = (table) => {
    if (!occupiedTables.includes(table)) {
      setOccupiedTables([...occupiedTables, table]);
    }
  };

  if (location.state && location.state.table) {
    const table = location.state.table;
    markTableAsOccupied(table);
  }

  const orderTypes = ['Dine-In', 'Take Away'];
  const locations = ['Hall', 'Outside', '1st Floor', '2nd Floor'];
  const tables = Array.from({ length: 12 }, (_, i) => `Table ${i + 1}`);

  const handleOrderTypeClick = (type) => {
    setSelectedOrderType(type);

    // If "Take Away" is selected, redirect directly to POSOrderScreen
    if (type === 'Take Away') {
      navigate(`/mockup/order?orderType=${type}&location=none&table=none`);
    }
  };

  const handleTableSelect = (table) => {
    setSelectedTable(table);
    navigate(`/mockup/order?table=${table}&orderType=${selectedOrderType}&location=${selectedLocation}`);

  };

  return (
    <Box p={2} style={{ minHeight: '100vh' }}>
      <Typography variant="h4" mb={3} align="center">POS - Table Orders</Typography>

      {/* Order Type Section */}
      <Box display="flex" justifyContent="center" mb={4}>
        {orderTypes.map((type) => (
          <Button
            key={type}
            variant={selectedOrderType === type ? 'contained' : 'outlined'}
            onClick={() => handleOrderTypeClick(type)}
            style={{
              width: '120px',
              height: '50px',
              margin: '0 10px',
              fontSize: '16px',
            }}
          >
            {type}
          </Button>
        ))}
      </Box>

      {/* Table Location Section */}
      <Tabs
        value={locations.indexOf(selectedLocation)}
        onChange={(e, newValue) => setSelectedLocation(locations[newValue])}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        centered
        style={{ marginBottom: '20px' }}
      >
        {locations.map((location) => (
          <Tab key={location} label={location} />
        ))}
      </Tabs>

      {/* Table List Section */}
      <Box display="flex" flexWrap="wrap" justifyContent="center" mt={3} gap={3}>
        {tables.map((table) => (
          <Box key={table} style={{ margin: '10px', textAlign: 'center' }}>
            <Button
              variant={selectedTable === table ? 'contained' : 'outlined'}
              color={occupiedTables.includes(table) ? 'secondary' : 'primary'}
              onClick={() => handleTableSelect(table)}
              style={{
                width: '120px',
                height: '120px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '16px',
              }}
            >
              {table}
            </Button>
            {occupiedTables.includes(table) && (
              <Button
                size="small"
                variant="contained"
                color="info"
                onClick={() => navigate(`/mockup/order?table=${table}`)}
                style={{ marginTop: '10px', width: '120px' }}
              >
                Add Order
              </Button>
            )}
          </Box>
        ))}
      </Box>

      {/* Bottom Navigation */}
      <BottomNavigation
        value={navigationValue}
        onChange={(event, newValue) => setNavigationValue(newValue)}
        showLabels
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#fff',
          borderTop: '1px solid #ddd',
          boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
        }}
      >
        <BottomNavigationAction label="POS" icon={<RestaurantIcon />} onClick={() => navigate('/mockup/table')} />
        <BottomNavigationAction label="Orders" icon={<ListAltIcon />} onClick={() => navigate('/mockup/orders')} />
        <BottomNavigationAction label="Reports" icon={<ReportIcon />} onClick={() => navigate('/mockup/reports')} />
        <BottomNavigationAction label="Settings" icon={<SettingsIcon />} onClick={() => navigate('/mockup/settings')} />
      </BottomNavigation>
    </Box>
  );
};

export default TableOrderScreen;
