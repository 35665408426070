import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png'; // Import your logo image

function LoginPage({ setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch(`https://api.freegopos.com/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('username', data.username);
        localStorage.setItem('email', data.email);
        localStorage.setItem('photo', data.photo);
        localStorage.setItem('token', data.token);
        localStorage.setItem('role', data.role);
        localStorage.setItem('vendor_id', data.vendor_id);
        localStorage.setItem('user_id', data.user_id);

        setIsAuthenticated(true);

        if (data.vendorExpiringSoon) {
          alert('Your subscription is expiring soon. Please renew to avoid service interruption.');
        }

        navigate('/pos/table');
      } else {
        setError(data.message || 'Login failed');
      }
    } catch (err) {
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Box p={3} border="1px solid #ccc" borderRadius="8px" width="100%" maxWidth="400px">
        {/* Add logo */}
        <Box display="flex" justifyContent="center" mb={2}>
          <img src={logo} alt="Logo" style={{ width: '150px', height: 'auto' }} />
        </Box>
        <Typography variant="h5" mb={2}>Login</Typography>
        {error && <Typography color="error" mb={2}>{error}</Typography>}
        <form onSubmit={handleLogin}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </form>
      </Box>
    </Box>
  );
}

export default LoginPage;
