import React, { useState } from 'react';
import { Box, Grid, Typography, Button, TextField, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, ToggleButtonGroup, ToggleButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate, useLocation } from 'react-router-dom';

const POSOrderScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tableId = new URLSearchParams(location.search).get('table');
  const orderType = new URLSearchParams(location.search).get('orderType');
  const locationId = new URLSearchParams(location.search).get('location');

  const [selectedCategory, setSelectedCategory] = useState('Noodles');
  const [selectedSubCategory, setSelectedSubCategory] = useState('Mee');
  const [cartTotal, setCartTotal] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isCartOpen, setCartOpen] = useState(false); // State for cart dialog
  const [cartItems, setCartItems] = useState([]); // State to store cart items
  const [selectedFoodItem, setSelectedFoodItem] = useState(null);
  const [sugarLevel, setSugarLevel] = useState('');
  const [sauce, setSauce] = useState('');
  const [discount, setDiscount] = useState('');

  const categories = [
    { name: 'Noodles', subCategories: ['Mee', 'Bihun'] },
    { name: 'Burger', subCategories: [] },
    { name: 'Seafood', subCategories: [] },
    { name: 'Fruit Juice', subCategories: [] },
  ];

  const foodItems = [
    { name: 'Food Name ABC', price: 5.0 },
    { name: 'Food Name 313', price: 8.0 },
    { name: 'Food 6', price: 10.0 },
    { name: 'Food 7', price: 12.0 },
  ];

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedSubCategory(categories.find(c => c.name === category)?.subCategories[0] || null);
  };

  const handleAddToCart = () => {
    const priceAfterDiscount = selectedFoodItem.price - (selectedFoodItem.price * parseFloat(discount || 0) / 100);
    const updatedTotal = cartTotal + priceAfterDiscount;

    const newItem = {
      name: selectedFoodItem.name,
      price: priceAfterDiscount,
      sugarLevel,
      sauce
    };

    setCartItems([...cartItems, newItem]); // Add the selected item to cart
    setCartTotal(updatedTotal);
    setCartCount(cartCount + 1);
    setDialogOpen(false); // Close the modal after adding to cart
  };

  const openDialogForFoodItem = (foodItem) => {
    setSelectedFoodItem(foodItem);
    setDialogOpen(true);
  };

  const openCartDialog = () => {
    setCartOpen(true); // Open the cart dialog
  };

  const closeCartDialog = () => {
    setCartOpen(false); // Close the cart dialog
  };

  const handlePlaceOrder = () => {
    // After placing the order, return to the TableOrderScreen and mark the table as occupied
    navigate('/mockup/table', { state: { table: tableId } });
  };

  return (
    <Box p={2}>
      {/* Header with back and table info */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Button variant="text" onClick={() => navigate(-1)}>{'< Back'}</Button>
        <Typography variant="h6">Table: {tableId}</Typography>
      </Box>

      {/* Search and Filter */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <TextField variant="outlined" placeholder="Search item" size="small" style={{ width: '85%' }} />
        <IconButton>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* Main Category and Subcategory List */}
      <Box display="flex" height="100%" mb={2}>
        <Box width="25%" p={2} style={{ borderRight: '1px solid #ddd' }}>
          {categories.map((category) => (
            <Box key={category.name} mb={2}>
              <Typography
                variant="h6"
                style={{
                  cursor: 'pointer',
                  fontWeight: selectedCategory === category.name ? 'bold' : 'normal',
                  color: selectedCategory === category.name ? '#1976d2' : '#000',
                }}
                onClick={() => handleCategoryClick(category.name)}
              >
                {category.name}
              </Typography>
              {selectedCategory === category.name && category.subCategories.map((subCategory) => (
                <Typography
                  key={subCategory}
                  variant="body2"
                  style={{
                    marginLeft: '20px',
                    cursor: 'pointer',
                    fontWeight: selectedSubCategory === subCategory ? 'bold' : 'normal',
                    color: selectedSubCategory === subCategory ? '#1976d2' : '#000',
                  }}
                  onClick={() => setSelectedSubCategory(subCategory)}
                >
                  {'> '}{subCategory}
                </Typography>
              ))}
            </Box>
          ))}
        </Box>

        {/* Food List */}
        <Box width="75%" p={2}>
          <Grid container spacing={2}>
            {foodItems.map((item) => (
              <Grid item xs={6} key={item.name}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => openDialogForFoodItem(item)} // Open the dialog for food variants
                >
                  {item.name} - RM {item.price.toFixed(2)}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* Addon Modal for Variants */}
      <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)} fullWidth>
        <DialogTitle>Choose Addons for {selectedFoodItem?.name}</DialogTitle>
        <DialogContent>
          <Typography variant="body2" gutterBottom>Add on Sugar Level:</Typography>
          <ToggleButtonGroup
            value={sugarLevel}
            exclusive
            onChange={(e, value) => setSugarLevel(value)}
            fullWidth
          >
            <ToggleButton value="Less Sugar">Less Sugar</ToggleButton>
            <ToggleButton value="Kosong">Kosong</ToggleButton>
          </ToggleButtonGroup>

          <Typography variant="body2" gutterBottom>Add on Sauce:</Typography>
          <ToggleButtonGroup
            value={sauce}
            exclusive
            onChange={(e, value) => setSauce(value)}
            fullWidth
          >
            <ToggleButton value="Cheese">Cheese</ToggleButton>
            <ToggleButton value="Black Pepper">Black Pepper</ToggleButton>
          </ToggleButtonGroup>

          <Typography variant="body2" gutterBottom>Discount RM:</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            placeholder="e.g., 10%"
            margin="dense"
          />

          <Typography variant="body2" mt={2}>
            Selected: {sugarLevel}, {sauce}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleAddToCart}>Add to Cart</Button>
        </DialogActions>
      </Dialog>

      {/* Cart Modal */}
      <Dialog open={isCartOpen} onClose={closeCartDialog} fullWidth>
        <DialogTitle>Cart</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {cartItems.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Box display="flex" justifyContent="space-between">
                  <Typography>{item.name}</Typography>
                  <Typography>{item.price.toFixed(2)}</Typography>
                </Box>
                <Typography variant="body2">Sugar Level: {item.sugarLevel}</Typography>
                <Typography variant="body2">Sauce: {item.sauce}</Typography>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCartDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Cart and Place Order */}
      <Box display="flex" justifyContent="space-between" alignItems="center" p={2} style={{ backgroundColor: '#f0f0f0', position: 'fixed', bottom: 0, width: '100%' }}>
        <Button startIcon={<ShoppingCartIcon />} size="large" onClick={openCartDialog}>
          Cart ({cartCount})
        </Button>
        <Typography variant="h6">
          Total: RM{cartTotal.toFixed(2)}
        </Typography>
        <Button variant="contained" color="primary" onClick={handlePlaceOrder}>
          Place Order
        </Button>
      </Box>
    </Box>
  );
};

export default POSOrderScreen;
