import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Card, CardMedia, CardContent, Typography, IconButton } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CustomerSideNavigation from './CustomerSideNavigation';
import CustomerBottomNavigationBar from './CustomerBottomNavigationBar';
import CustomerTopNavigation from './CustomerTopNavigation';

function CustomerProductList() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const location = useLocation();
  const vendorId = new URLSearchParams(location.search).get('vendorId');
  const tableId = new URLSearchParams(location.search).get('tableId');

  // Fetch products when a category is selected
  const loadProductsByCategory = (categoryId) => {
    if (vendorId && categoryId) {
      fetch(`https://api.freegopos.com/Customer/Pos/Product/${vendorId}/${categoryId}`)
        .then(response => response.json())
        .then(data => {
          setProducts(data);
          setFilteredProducts(data); // Initialize filtered products with full product list
        })
        .catch(error => console.error('Error fetching products:', error));
    }

  };

  // Handle product search
  const handleSearch = (searchTerm) => {
    const filtered = products.filter(product =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  const addToCart = (product) => {
    let cartItems = JSON.parse(localStorage.getItem('cart_items')) || [];
    const existingProductIndex = cartItems.findIndex(item => item.id === product.id);

    if (existingProductIndex >= 0) {
      // If the product is already in the cart, increase its quantity and update the total amount
      cartItems[existingProductIndex].quantity += 1;
      cartItems[existingProductIndex].totalAmount += parseFloat(product.price);
    } else {
      // If the product is not in the cart, add it with a quantity of 1 and set the total amount
      cartItems.push({
        ...product,
        quantity: 1,
        totalAmount: parseFloat(product.price),
        tableId
      });
    }

    // cartItems.push({ ...product, quantity: 1, tableId });
    localStorage.setItem('cart_items', JSON.stringify(cartItems));
    alert(`${product.name} added to cart!`);
  };

  return (
    <div className="app-container" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Top Navigation with Search */}
      <CustomerTopNavigation onSearch={handleSearch} />

      {/* Side Navigation */}
      <div className="content-container" style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        <div className="side-nav" style={{ width: '100px', flexShrink: 0, backgroundColor: '#fff' }}>
          <CustomerSideNavigation vendorId={vendorId} onCategorySelect={loadProductsByCategory} />
        </div>

        {/* Product List */}
        <div className="main-content" style={{ flexGrow: 1, padding: '20px', overflowY: 'auto' }}>
          <Box p={2} display="flex" flexWrap="wrap" gap={2}>
            {filteredProducts.length === 0 ? (
              <Typography variant="body1" color="textSecondary">
                No products available. Please select a category or search for products.
              </Typography>
            ) : (
              filteredProducts.map(product => (
                <Card key={product.id} component={Link} to={`/customer/product/detail?vendorId=${vendorId}&tableId=${tableId}&productId=${product.id}`} style={{ textDecoration: 'none', position: 'relative', paddingBottom: '30px' }}>
                  {product.image_path ? (
                    <CardMedia
                      component="img"
                      height="140"
                      image={product.image_path}
                      alt={product.name}
                      crossOrigin="anonymous"
                    />
                  ) : null}
                  <CardContent>
                    <Typography variant="body2"
                      style={{
                        whiteSpace: 'normal', // Allow text to wrap
                        wordWrap: 'break-word' // Handle long words
                      }}>{product.name}</Typography>
                    <Typography variant="body2">RM {Number(product.price).toFixed(2)}</Typography>
                  </CardContent>
                  <IconButton
                    style={{ position: 'absolute', bottom: '10px', right: '10px', backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
                    onClick={(e) => { e.preventDefault(); addToCart(product); }}
                  >
                    <AddShoppingCartIcon />
                  </IconButton>
                </Card>
              ))
            )}
          </Box>
        </div>
      </div>

      {/* Bottom Navigation */}
      <div className="bottom-nav" style={{ marginTop: 'auto', width: '100%', backgroundColor: '#fff', boxShadow: '0 -2px 5px rgba(0,0,0,0.1)', position: 'sticky', bottom: 0, left: 0 }}>
        <CustomerBottomNavigationBar />
      </div>
    </div>
  );
}

export default CustomerProductList;
