import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CircularProgress, IconButton } from '@mui/material';
import BottomNavigationBar from './BottomNavigationBar';
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu';  // Import HamburgerMenu component

function TablePage() {
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Hook for navigating to another page

  useEffect(() => {
    const fetchTables = async () => {
      const vendorId = localStorage.getItem('vendor_id');
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`https://api.freegopos.com/Table/List/${vendorId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setTables(data || []);  // Assuming the API returns a key named `tables`
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch tables:', error);
        setLoading(false);
      }
    };

    fetchTables();
  }, []);

  const handleTableClick = (table) => {
    // Store selected table in localStorage
    localStorage.setItem('selected_table', JSON.stringify(table));

    // Navigate to the home/product page to start the order
    navigate('/'); // Replace '/' with the actual path to your home or product list page
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={2}>
      <IconButton onClick={() => window.history.back()}><span>&larr;</span></IconButton>
      <HamburgerMenu />
      <Typography variant="h6" mb={2}>Tables</Typography>
      <Grid container spacing={2}>
        {tables.length > 0 ? (
          tables.map((table) => (
            <Grid item xs={12} sm={6} md={4} key={table.id}>
              <Card onClick={() => handleTableClick(table)} style={{ cursor: 'pointer' }}>
                <CardContent>
                  <Typography variant="h6">Table {table.tableName}</Typography>
                  <Typography variant="body2">Location: {table.location}</Typography>
                  <Typography variant="body2">Status: {table.status}</Typography>
                  <Typography variant="body2">Capacity: {table.capacity}</Typography>
                  {/* Display QR code image */}
                  {table.qrcode && (
                    <Box mt={2}>
                      <img src={table.qrcode} alt={`QR Code for ${table.tableName}`} style={{ width: '100%', height: 'auto' }} crossOrigin="anonymous" />
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography>No tables available.</Typography>
        )}
      </Grid>
      {/* <Box className="bottom-nav" style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: '#fff', boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)' }}>
        <BottomNavigationBar />
      </Box> */}
    </Box>
  );
}

export default TablePage;
