import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Divider, Button, IconButton } from '@mui/material';
import BottomNavigationBar from './BottomNavigationBar';
import { useNavigate } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu';  // Import HamburgerMenu component

function CartPage() {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedTable, setSelectedTable] = useState(null);
  const [vendorId] = useState(localStorage.getItem('vendor_id'));
  const [userId] = useState(localStorage.getItem('user_id'));
  const [token] = useState(localStorage.getItem('token'));

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cart_items')) || [];
    setCartItems(storedCartItems);

    const storedTable = JSON.parse(localStorage.getItem('selected_table'));
    setSelectedTable(storedTable);

    const calculatedTotal = storedCartItems.reduce((sum, item) => sum + item.totalAmount, 0);
    setTotal(calculatedTotal);
  }, []);

  const handleRemoveItem = (id) => {
    const updatedCartItems = cartItems.filter(item => item.id !== id);
    setCartItems(updatedCartItems);
    localStorage.setItem('cart_items', JSON.stringify(updatedCartItems));

    const newTotal = updatedCartItems.reduce((sum, item) => sum + item.totalAmount, 0);
    setTotal(newTotal);
  };

  const handlePlaceOrder = async (redirectAfterOrder = 'home') => {
    const itemsWithSpecifications = cartItems.map(item => ({
      productId: item.id,
      price: item.price,
      name: item.name,
      quantity: item.quantity,
      addons: item.selectedAddOn || '',
      toppings: item.selectedToppings?.length > 0 ? item.selectedToppings.join(', ') : '',
      specifications: item.selectedSpecifications ? Object.values(item.selectedSpecifications).map(spec => Array.isArray(spec.optionName) ? spec.optionName.join(', ') : spec.optionName).join(', ') : '',
      totalPrice: item.totalAmount
    }));

    console.log({ selectedTable })
    const newOrder = {
      customerId: 0,
      orderType: selectedTable ? 'Dine-In' : 'Take-Away',
      tableId: selectedTable ? selectedTable.id : null,
      items: itemsWithSpecifications,
      status: 'pending',
      amountToPay: total,
      userId
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/Pos/Order/${vendorId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newOrder)
      });

      const orderData = await response.json();

      localStorage.removeItem('cart_items');
      localStorage.removeItem('selected_table');
      setCartItems([]);
      setSelectedTable([]);
      setTotal(0);

      if (redirectAfterOrder === 'checkout') {
        navigate(`/checkout?orderId=${orderData.id}`);
      } else {
        navigate('/home');
      }
    } catch (error) {
      console.error('Error placing order:', error);
    }
  };

  const handleCheckout = () => {
    if (!cartItems.length) {
      alert('Your cart is empty!');
      return;
    }

    handlePlaceOrder('checkout');
  };

  if (cartItems.length === 0) {
    return (
      <Box style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Box p={2} mt={0} style={{ flexGrow: 1 }}>
          <IconButton onClick={() => window.history.back()}><span>&larr;</span></IconButton>
          <HamburgerMenu />

          <Typography variant="h6" mb={2}>Your Cart</Typography>

          {selectedTable && (
            <Box mb={2}>
              <Typography variant="subtitle1" fontWeight="bold">Selected Table</Typography>
              <Typography variant="body2">Table Name: {selectedTable.tableName}</Typography>
              <Typography variant="body2">Location: {selectedTable.location}</Typography>
              <Divider />
            </Box>
          )}

          <Typography>No items in the cart.</Typography>
        </Box>

        {/* <Box style={{ position: 'sticky', bottom: 0, left: 0, width: '100%', backgroundColor: '#fff', boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)' }}>
          <BottomNavigationBar />
        </Box> */}
      </Box>
    );
  }

  return (
    <Box style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box p={2} mt={0} style={{ flexGrow: 1, overflowY: 'auto' }}>
        <IconButton onClick={() => window.history.back()}><span>&larr;</span></IconButton>
        <HamburgerMenu />

        <Typography variant="h6" mb={2}>Your Cart</Typography>

        {selectedTable && (
          <Box mb={2}>
            <Typography variant="subtitle1" fontWeight="bold">Selected Table</Typography>
            <Typography variant="body2">Table Name: {selectedTable.tableName}</Typography>
            <Typography variant="body2">Location: {selectedTable.location}</Typography>
            <Divider />
          </Box>
        )}

        <List>
          {cartItems.map(item => (
            <React.Fragment key={item.id}>
              <ListItem>
                <ListItemText
                  primary={item.name}
                  secondary={
                    <>
                      <Typography variant="body2">Price: RM {Number(item.price).toFixed(2)}</Typography>
                      <Typography variant="body2">Quantity: {item.quantity}</Typography>

                      {item.selectedSpecifications && Object.keys(item.selectedSpecifications).length > 0 && (
                        <Typography variant="body2">
                          Specifications:{" "}
                          {Object.values(item.selectedSpecifications).map((spec, index) => (
                            <React.Fragment key={index}>
                              {Array.isArray(spec.optionName) ? (
                                spec.optionName.map((name, i) => (
                                  <span key={`${name}-${i}`}>{name} (+RM {spec.extraCost[i]}){i < spec.optionName.length - 1 && ', '}</span>
                                ))
                              ) : (
                                <span>{spec.optionName} (+RM {spec.extraCost})</span>
                              )}
                              {index < Object.values(item.selectedSpecifications).length - 1 && ', '}
                            </React.Fragment>
                          ))}
                        </Typography>
                      )}

                      {item.selectedAddOn && (
                        <Typography variant="body2">Add-On: {item.selectedAddOn}</Typography>
                      )}

                      {item.selectedToppings && item.selectedToppings.length > 0 && (
                        <Typography variant="body2">Toppings: {item.selectedToppings.join(', ')}</Typography>
                      )}
                    </>
                  }
                />
                <Typography variant="body2">RM {Number(item.totalAmount).toFixed(2)}</Typography>
                <IconButton onClick={() => handleRemoveItem(item.id)}><span>&times;</span></IconButton>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Box>

      <Box p={2} style={{ backgroundColor: '#fff', boxShadow: '0 -2px 5px rgba(0,0,0,0.1)' }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">Total: RM {Number(total).toFixed(2)}</Typography>
          <Button variant="outlined" color="secondary" onClick={() => handlePlaceOrder()}>Place Order</Button>
          <Button variant="contained" color="primary" onClick={handleCheckout}>Checkout</Button>
        </Box>
      </Box>

      {/* <Box style={{ position: 'sticky', bottom: 0, left: 0, width: '100%', backgroundColor: '#fff', boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)' }}>
        <BottomNavigationBar />
      </Box> */}
    </Box>
  );
}

export default CartPage;
