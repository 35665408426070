import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, FormControlLabel, Checkbox, Radio, RadioGroup, IconButton } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import BottomNavigationBar from './BottomNavigationBar';
import HamburgerMenu from './HamburgerMenu';  // Import HamburgerMenu component

function ProductDetail() {
  const { vendorId, productId } = useParams();
  const [product, setProduct] = useState(null);
  const [specifications, setSpecifications] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [selectedAddOn, setSelectedAddOn] = useState('');
  const [selectedToppings, setSelectedToppings] = useState([]);
  const [selectedSpecifications, setSelectedSpecifications] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/Product/Specifications/${vendorId}/${productId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setProduct(data.product[0] || {});
        setSpecifications(data.specifications || []);
      } catch (error) {
        console.error('Failed to fetch product details:', error);
      }
    };

    fetchProductDetails();
  }, [vendorId, productId]);

  useEffect(() => {
    if (product) {
      calculateTotalAmount();
    }
  }, [product, selectedAddOn, selectedToppings, selectedSpecifications, quantity]);

  const handleAddToCart = () => {
    const addOnDetails = selectedAddOn
      ? JSON.parse(product.add_ons || '[]').find(addOn => addOn.name === selectedAddOn)
      : null;

    const toppingDetails = selectedToppings.map(toppingName =>
      JSON.parse(product.toppings || '[]').find(topping => topping.name === toppingName)
    );

    const cartItem = {
      id: product.id,
      name: product.name,
      price: parseFloat(product.price) || 0,
      totalAmount: totalAmount,
      quantity: quantity,
      selectedAddOn: addOnDetails ? `${addOnDetails.name} (+RM ${addOnDetails.price})` : '',
      selectedToppings: toppingDetails.map(topping => `${topping.name} (+RM ${topping.price})`),
      selectedSpecifications: selectedSpecifications,
    };

    const existingCartItems = JSON.parse(localStorage.getItem('cart_items')) || [];
    const updatedCartItems = [...existingCartItems, cartItem];

    localStorage.setItem('cart_items', JSON.stringify(updatedCartItems));
    alert(`Added ${quantity} of ${product.name} to cart with total amount RM ${Number(totalAmount).toFixed(2)}!`);
    navigate(`/home`);
  };

  const handleQuantityChange = (change) => {
    setQuantity(prevQuantity => Math.max(1, prevQuantity + change));
  };

  const handleAddOnChange = (event) => {
    setSelectedAddOn(event.target.value);
  };

  const handleToppingChange = (event) => {
    const { value } = event.target;
    setSelectedToppings(prev =>
      prev.includes(value)
        ? prev.filter(item => item !== value)
        : [...prev, value]
    );
  };

  const handleSpecificationChange = (specId, optionId, optionName, extraCost, type) => {
    setSelectedSpecifications(prev => {
      const updated = { ...prev };

      if (type === 'radio') {
        updated[specId] = { optionId, optionName, extraCost };
      } else if (type === 'checkbox') {
        const currentOptions = updated[specId]?.options || [];
        const currentNames = updated[specId]?.optionName || [];
        const currentExtraCosts = updated[specId]?.extraCost || [];

        const optionIndex = currentOptions.indexOf(optionId);

        if (optionIndex !== -1) {
          updated[specId] = {
            ...updated[specId],
            options: currentOptions.filter((_, index) => index !== optionIndex),
            optionName: currentNames.filter((_, index) => index !== optionIndex),
            extraCost: currentExtraCosts.filter((_, index) => index !== optionIndex),
          };

          if (updated[specId].options.length === 0) {
            delete updated[specId];
          }
        } else {
          updated[specId] = {
            ...updated[specId],
            options: [...currentOptions, optionId],
            optionName: [...currentNames, optionName],
            extraCost: [...currentExtraCosts, extraCost],
          };
        }
      }

      return updated;
    });
  };

  const calculateTotalAmount = () => {
    let baseAmount = parseFloat(product.price) || 0;

    if (selectedAddOn) {
      const addOn = JSON.parse(product.add_ons || '[]').find(addOn => addOn.name === selectedAddOn);
      if (addOn) baseAmount += parseFloat(addOn.price);
    }

    selectedToppings.forEach(toppingName => {
      const topping = JSON.parse(product.toppings || '[]').find(topping => topping.name === toppingName);
      if (topping) baseAmount += parseFloat(topping.price);
    });

    Object.values(selectedSpecifications).forEach(spec => {
      if (spec.options) {
        spec.options.forEach((optionId, index) => {
          const extraCost = parseFloat(spec.extraCost[index]);
          baseAmount += extraCost;
        });
      } else {
        baseAmount += parseFloat(spec.extraCost);
      }
    });

    setTotalAmount(baseAmount * quantity);
  };

  if (!product || !product.name) {
    return <Typography>Loading...</Typography>;
  }

  const addOns = product.add_ons ? JSON.parse(product.add_ons) : [];
  const toppings = product.toppings ? JSON.parse(product.toppings) : [];

  return (
    <Box style={{ paddingBottom: '80px', position: 'relative' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Typography variant="h5" mb={2}>{product.name}</Typography>
        <HamburgerMenu /> {/* Add HamburgerMenu on the top-right */}
      </Box>

      {product.image_path ? (
        <img
          src={product.image_path}
          alt={product.name}
          style={{ width: '100%', height: 'auto', marginBottom: '20px' }}
          crossOrigin="anonymous"
        />
      ) : null}

      <Box p={2}>
        <Typography variant="body1" mb={2}>{product.description}</Typography>
        <Typography variant="h6" fontWeight="bold">RM {Number(product.price).toFixed(2)}</Typography>

        {specifications.length > 0 ? (
          specifications.map(spec => (
            <div key={spec.id}>
              <Typography variant="body2">{spec.name}</Typography>
              {spec.input_type === 'radio' ? (
                <RadioGroup
                  value={selectedSpecifications[spec.id]?.optionId || ''}
                  onChange={(e) => {
                    const selectedOption = spec.options.find(option => option.option_id === parseInt(e.target.value));
                    handleSpecificationChange(spec.id, selectedOption.option_id, selectedOption.option_name, selectedOption.extra_cost, 'radio');
                  }}
                >
                  {spec.options.map(option => (
                    <FormControlLabel
                      key={option.option_id}
                      value={option.option_id}
                      control={<Radio />}
                      label={`${option.option_name} (+RM ${Number(option.extra_cost).toFixed(2)})`}
                    />
                  ))}
                </RadioGroup>

              ) : (
                spec.options.map(option => (
                  <FormControlLabel
                    key={option.option_id}
                    control={
                      <Checkbox
                        checked={!!selectedSpecifications[spec.id]?.options?.includes(option.option_id)}
                        onChange={() => handleSpecificationChange(spec.id, option.option_id, option.option_name, option.extra_cost, 'checkbox')}
                      />
                    }
                    label={`${option.option_name} (+RM ${Number(option.extra_cost).toFixed(2)})`}
                  />
                ))
              )}
            </div>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">No additional specifications available.</Typography>
        )}

        {addOns.length > 0 && (
          <Box mt={2}>
            <Typography variant="body2" fontWeight="bold">Add-Ons:</Typography>
            <RadioGroup value={selectedAddOn} onChange={handleAddOnChange}>
              {addOns.map((addOn, index) => (
                <FormControlLabel
                  key={`addon-${index}`}
                  value={addOn.name}
                  control={<Radio />}
                  label={`${addOn.name} (+RM ${Number(addOn.price).toFixed(2)})`}
                />
              ))}
            </RadioGroup>
          </Box>
        )}

        {toppings.length > 0 && (
          <Box mt={2}>
            <Typography variant="body2" fontWeight="bold">Toppings:</Typography>
            {toppings.map((topping, index) => (
              <FormControlLabel
                key={`topping-${index}`}
                control={<Checkbox value={topping.name} onChange={handleToppingChange} />}
                label={`${topping.name} (+RM ${Number(topping.price).toFixed(2)})`}
              />
            ))}
          </Box>
        )}

        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => handleQuantityChange(-1)}>
              <RemoveIcon />
            </IconButton>
            <Typography variant="body1">{quantity}</Typography>
            <IconButton onClick={() => handleQuantityChange(1)}>
              <AddIcon />
            </IconButton>
          </Box>
          <Typography variant="h6" fontWeight="bold">Total: RM {Number(totalAmount).toFixed(2)}</Typography>
        </Box>

        <Box mt={2} display="flex" gap={2}>
          <Button variant="outlined" fullWidth onClick={() => navigate(-1)}>Back</Button>
          <Button variant="outlined" fullWidth onClick={handleAddToCart}>Buy Now</Button>
          <Button variant="contained" color="primary" fullWidth onClick={handleAddToCart}>Add to Cart</Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ProductDetail;
