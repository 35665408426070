import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, Button, FormControlLabel, Checkbox, Radio, RadioGroup, IconButton } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import BottomNavigationBar from './CustomerBottomNavigationBar';

function CustomerProductDetail() {
  const [product, setProduct] = useState(null);
  const [specifications, setSpecifications] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [selectedAddOn, setSelectedAddOn] = useState('');
  const [selectedToppings, setSelectedToppings] = useState([]);
  const [selectedSpecifications, setSelectedSpecifications] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const vendorId = new URLSearchParams(location.search).get('vendorId');
  const tableId = new URLSearchParams(location.search).get('tableId');
  const productId = new URLSearchParams(location.search).get('productId');

  useEffect(() => {
    const fetchProductDetails = async () => {
      if (!vendorId || !productId) return;
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/Customer/Pos/Product/Specifications/${vendorId}/${productId}`);
        const data = await response.json();
        setProduct(data.product[0] || {}); // Default to an empty object if undefined
        setSpecifications(data.specifications || []);  // Default to an empty array if undefined
      } catch (error) {
        console.error('Failed to fetch product details:', error);
      }
    };

    fetchProductDetails();
  }, [vendorId, productId]);

  useEffect(() => {
    if (product) {
      calculateTotalAmount();
    }
  }, [product, selectedAddOn, selectedToppings, selectedSpecifications, quantity]);

  const handleAddToCart = () => {
    const addOnDetails = selectedAddOn
      ? JSON.parse(product.add_ons || '[]').find(addOn => addOn.name === selectedAddOn)
      : null;

    const toppingDetails = selectedToppings.map(toppingName =>
      JSON.parse(product.toppings || '[]').find(topping => topping.name === toppingName)
    );

    const cartItem = {
      id: product.id,
      name: product.name,
      price: parseFloat(product.price) || 0,
      totalAmount: totalAmount,
      quantity: quantity,
      selectedAddOn: addOnDetails ? `${addOnDetails.name} (+RM ${addOnDetails.price})` : '',
      selectedToppings: toppingDetails.map(topping => `${topping.name} (+RM ${topping.price})`),
      selectedSpecifications: selectedSpecifications,
    };

    // Retrieve existing cart items from localStorage
    const existingCartItems = JSON.parse(localStorage.getItem('cart_items')) || [];

    // Add the new item to the cart
    const updatedCartItems = [...existingCartItems, cartItem];

    // Save the updated cart items back to localStorage
    localStorage.setItem('cart_items', JSON.stringify(updatedCartItems));

    alert(`Added ${quantity} of ${product.name} to cart with total amount RM ${Number(totalAmount).toFixed(2)}!`);
    // navigate(`/customer/cart?vendorId=${vendorId}&tableId=${tableId}`);
    navigate(`/customer/products?vendorId=${vendorId}&tableId=${tableId}`)

  };


  const handleQuantityChange = (change) => {
    setQuantity(prevQuantity => Math.max(1, prevQuantity + change));
  };

  const handleAddOnChange = (event) => {
    setSelectedAddOn(event.target.value);
  };

  const handleToppingChange = (event) => {
    const { value } = event.target;
    setSelectedToppings(prev =>
      prev.includes(value)
        ? prev.filter(item => item !== value)
        : [...prev, value]
    );
  };

  const handleSpecificationChange = (specId, optionId, optionName, extraCost, type) => {
    setSelectedSpecifications(prev => {
      const updated = { ...prev };

      if (type === 'radio') {
        updated[specId] = { optionId, optionName, extraCost };
      } else if (type === 'checkbox') {
        const currentOptions = updated[specId]?.options || [];
        const currentNames = updated[specId]?.optionName || [];
        const currentExtraCosts = updated[specId]?.extraCost || [];

        const optionIndex = currentOptions.indexOf(optionId);

        if (optionIndex !== -1) {
          // Remove the option if it's already selected
          updated[specId] = {
            ...updated[specId],
            options: currentOptions.filter((_, index) => index !== optionIndex),
            optionName: currentNames.filter((_, index) => index !== optionIndex),
            extraCost: currentExtraCosts.filter((_, index) => index !== optionIndex),
          };

          // If there are no more options selected, remove the specification entry
          if (updated[specId].options.length === 0) {
            delete updated[specId];
          }
        } else {
          // Add the option if it's not selected
          updated[specId] = {
            ...updated[specId],
            options: [...currentOptions, optionId],
            optionName: [...currentNames, optionName],
            extraCost: [...currentExtraCosts, extraCost],
          };
        }
      }

      return updated;
    });
  };


  const calculateTotalAmount = () => {
    let baseAmount = parseFloat(product.price) || 0;

    // Add cost of selected add-on
    if (selectedAddOn) {
      const addOn = JSON.parse(product.add_ons || '[]').find(addOn => addOn.name === selectedAddOn);
      if (addOn) baseAmount += parseFloat(addOn.price);
    }

    // Add cost of selected toppings
    selectedToppings.forEach(toppingName => {
      const topping = JSON.parse(product.toppings || '[]').find(topping => topping.name === toppingName);
      if (topping) baseAmount += parseFloat(topping.price);
    });

    // Add cost of selected specifications
    Object.values(selectedSpecifications).forEach(spec => {
      if (spec.options) {
        console.log({ 'ss': spec.options, spec })
        // When dealing with checkboxes, sum up each option's extra cost
        spec.options.forEach((optionId, index) => {
          const extraCost = parseFloat(spec.extraCost[index]);
          baseAmount += extraCost;
        });
      } else {
        console.log('sdasd')
        // For radio buttons, there's only one extra cost to add
        baseAmount += parseFloat(spec.extraCost);
      }
    });

    // Calculate total for the quantity
    setTotalAmount(baseAmount * quantity);
  };

  if (!product || !product.name) {
    return <Typography>Loading...</Typography>;
  }

  const addOns = product.add_ons ? JSON.parse(product.add_ons) : [];
  const toppings = product.toppings ? JSON.parse(product.toppings) : [];

  return (
    <Box style={{ paddingBottom: '80px', position: 'relative' }}>
      <IconButton onClick={() => window.history.back()}><span>&larr;</span></IconButton>
      <Box p={2}>
        <Typography variant="h5" mb={2}>{product.name}</Typography>

        {/* Check if image_path is valid before rendering the image */}
        {product.image_path ? (
          <img
            src={product.image_path}
            alt={product.name}
            style={{ width: '100%', height: 'auto', marginBottom: '20px' }}
            crossOrigin="anonymous"
          />
        ) : null}

        <Typography variant="body1" mb={2}>{product.description}</Typography>
        <Typography variant="h6" fontWeight="bold">RM {Number(product.price).toFixed(2)}</Typography>

        {/* Specifications */}
        {specifications.length > 0 ? (
          specifications.map(spec => (
            <div key={spec.id}>
              <Typography variant="body2">{spec.name}</Typography>
              {spec.input_type === 'radio' ? (
                <RadioGroup
                  value={selectedSpecifications[spec.id]?.optionId || ''}
                  onChange={(e) => {
                    const selectedOption = spec.options.find(option => option.option_id === parseInt(e.target.value));
                    handleSpecificationChange(spec.id, selectedOption.option_id, selectedOption.option_name, selectedOption.extra_cost, 'radio');
                  }}
                >
                  {spec.options.map(option => (
                    <FormControlLabel
                      key={option.option_id}
                      value={option.option_id}
                      control={<Radio />}
                      label={`${option.option_name} (+RM ${Number(option.extra_cost).toFixed(2)})`}
                    />
                  ))}
                </RadioGroup>
              ) : (
                spec.options.map(option => (
                  <FormControlLabel
                    key={option.option_id}
                    control={
                      <Checkbox
                        checked={!!selectedSpecifications[spec.id]?.options?.includes(option.option_id)}
                        onChange={() => handleSpecificationChange(spec.id, option.option_id, option.option_name, option.extra_cost, 'checkbox')}
                      />
                    }
                    label={`${option.option_name} (+RM ${Number(option.extra_cost).toFixed(2)})`}
                  />
                ))
              )}
            </div>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">No additional specifications available.</Typography>
        )}

        {/* Add-Ons */}
        {addOns.length > 0 && (
          <Box mt={2}>
            <Typography variant="body2" fontWeight="bold">Add-Ons:</Typography>
            <RadioGroup value={selectedAddOn} onChange={handleAddOnChange}>
              {addOns.map((addOn, index) => (
                <FormControlLabel
                  key={`addon-${index}`}
                  value={addOn.name}
                  control={<Radio />}
                  label={`${addOn.name} (+RM ${Number(addOn.price).toFixed(2)})`}
                />
              ))}
            </RadioGroup>
          </Box>
        )}

        {/* Toppings */}
        {toppings.length > 0 && (
          <Box mt={2}>
            <Typography variant="body2" fontWeight="bold">Toppings:</Typography>
            {toppings.map((topping, index) => (
              <FormControlLabel
                key={`topping-${index}`}
                control={<Checkbox value={topping.name} onChange={handleToppingChange} />}
                label={`${topping.name} (+RM ${Number(topping.price).toFixed(2)})`}
              />
            ))}
          </Box>
        )}

        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => handleQuantityChange(-1)}>
              <RemoveIcon />
            </IconButton>
            <Typography variant="body1">{quantity}</Typography>
            <IconButton onClick={() => handleQuantityChange(1)}>
              <AddIcon />
            </IconButton>
          </Box>
          <Typography variant="h6" fontWeight="bold">Total: RM {Number(totalAmount).toFixed(2)}</Typography>
        </Box>

        <Box mt={2} display="flex" gap={2}>
          {/* <Button variant="outlined" fullWidth onClick={handleAddToCart}>Buy Now</Button> */}
          <Button variant="contained" color="primary" fullWidth onClick={handleAddToCart}>Add to Cart</Button>
        </Box>
      </Box>

      <Box className="bottom-nav" style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: '#fff', boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)' }}>
        <BottomNavigationBar />
      </Box>
    </Box>
  );
}

export default CustomerProductDetail;
