import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Add useNavigate for routing
import { Box, Typography, List, ListItem, ListItemText, Divider, IconButton, Card, CardContent, Button } from '@mui/material'; // Import Button from Material UI
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BottomNavigationBar from './CustomerBottomNavigationBar';

function CustomerOrderSummary() {
  const [order, setOrder] = useState(null);
  const location = useLocation();
  const vendorId = new URLSearchParams(location.search).get('vendorId');
  const tableId = new URLSearchParams(location.search).get('tableId');
  const orderId = new URLSearchParams(location.search).get('orderId');
  const customerId = localStorage.getItem('customerId');
  const navigate = useNavigate(); // Use the navigate function for redirection

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        let orderData;
        if (orderId) {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/Customer/Order/Detail/${vendorId}/${tableId}/${orderId}`);
          orderData = await response.json();
        } else {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/Customer/Order/GetByCustomerID/${vendorId}/${tableId}/${customerId}`);
          orderData = await response.json();
        }

        setOrder(orderData);
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    fetchOrder();
  }, [orderId, customerId, vendorId, tableId]);

  if (!order) {
    return (
      <Box style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Box p={2} mt={0} style={{ flexGrow: 1 }}>
          <IconButton onClick={() => window.history.back()}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" mb={2}>Order Summary</Typography>
          <Typography variant="body1" mb={2}>No order found</Typography>
          <Divider />
        </Box>
        <Box style={{ position: 'sticky', bottom: 0, left: 0, width: '100%', backgroundColor: '#fff', boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)' }}>
          <BottomNavigationBar />
        </Box>
      </Box>
    );
  }

  return (
    <Box style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box p={2} mt={0} style={{ flexGrow: 1 }}>
        <IconButton onClick={() => window.history.back()}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" mb={2}>Order Summary</Typography>

        <Card elevation={2} sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="body1"><strong>Order ID:</strong> {order.id}</Typography>
            <Typography variant="body1"><strong>Order Type:</strong> {order.order_type}</Typography>
            <Typography variant="body1"><strong>Table:</strong> {order.table.table_name}</Typography>
            <Typography variant="body1"><strong>Status:</strong> {order.status}</Typography>
          </CardContent>
        </Card>

        <Typography variant="h6" mt={2}>Items:</Typography>
        <List>
          {order.items.map(item => (
            <React.Fragment key={item.id}>
              <ListItem>
                <ListItemText
                  primary={item.name}
                  secondary={
                    <>
                      <Typography variant="body2">Quantity: {item.quantity}</Typography>
                      <Typography variant="body2">Price: RM {item.price}</Typography>

                      {/* Display Add-ons */}
                      {item.add_ons && (
                        <Typography variant="body2">Add-On: {item.add_ons}</Typography>
                      )}

                      {/* Display Toppings */}
                      {item.toppings && item.toppings.length > 0 && (
                        <Typography variant="body2">Toppings: {item.toppings}</Typography>
                      )}

                      {/* Display Specifications */}
                      {item.specifications && item.specifications.length > 0 && (
                        <Typography variant="body2">
                          Specifications:{" "}
                          {item.specifications.map((spec, index) => (
                            <React.Fragment key={index}>
                              {spec.name}:{" "}
                              {spec.options.map((option, i) => (
                                <span key={`${option.option_name}-${i}`}>
                                  {option.option_name} (+RM {option.extra_cost})
                                  {i < spec.options.length - 1 && ", "}
                                </span>
                              ))}
                              {index < item.specifications.length - 1 && ", "}
                            </React.Fragment>
                          ))}
                        </Typography>
                      )}
                    </>
                  }
                />
                <Typography variant="body2" fontWeight="bold">
                  Total: RM {Number(item.total_price).toFixed(2)}
                </Typography>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>

        {/* Edit Order Button */}
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            // Save the current order to localStorage
            const cartItems = order.items.map(item => ({
              id: item.product_id,
              name: item.name,
              price: parseFloat(item.price),
              totalAmount: parseFloat(item.total_price),
              quantity: item.quantity,
              selectedAddOn: item.add_ons,
              selectedToppings: item.toppings ? item.toppings.split(", ") : [],
              selectedSpecifications: item.specifications.reduce((acc, spec) => {
                acc[spec.id] = {
                  optionName: spec.options.map(option => option.option_name),
                  extraCost: spec.options.map(option => option.extra_cost),
                };
                return acc;
              }, {}),
            }));
            localStorage.setItem('cart_items', JSON.stringify(cartItems));

            // Redirect to product list or cart page
            navigate(`/customer/cart?vendorId=${vendorId}&tableId=${tableId}`);
          }}
        >
          Edit Order
        </Button>

        {/* Reorder Button */}
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            const cartItems = order.items.map(item => ({
              id: item.product_id,
              name: item.name,
              price: parseFloat(item.price),
              totalAmount: parseFloat(item.total_price),
              quantity: item.quantity,
              selectedAddOn: item.add_ons,
              selectedToppings: item.toppings ? item.toppings.split(", ") : [],
              selectedSpecifications: item.specifications.reduce((acc, spec) => {
                acc[spec.id] = {
                  optionName: spec.options.map(option => option.option_name),
                  extraCost: spec.options.map(option => option.extra_cost),
                };
                return acc;
              }, {}),
            }));
            localStorage.setItem('cart_items', JSON.stringify(cartItems));
            alert('Order added to cart! You can now modify it.');

            // Redirect to the cart or product page
            navigate(`/customer/cart?vendorId=${vendorId}&tableId=${tableId}`);
          }}
        >
          Reorder
        </Button>
      </Box>

      <Box style={{ position: 'sticky', bottom: 0, left: 0, width: '100%', backgroundColor: '#fff', boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)' }}>
        <BottomNavigationBar />
      </Box>
    </Box>
  );
}

export default CustomerOrderSummary;
