import React from 'react';
import { Box, Typography, Button, Divider, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu'; // Import the HamburgerMenu component

function ProfilePage({ setIsAuthenticated }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to log out?");
    if (confirmLogout) {
      // Clear localStorage
      localStorage.clear();
      // Update authentication state
      setIsAuthenticated(false);
      // Redirect to login page
      navigate('/login');
    }
  };

  const userProfile = {
    name: localStorage.getItem('username'),
    email: localStorage.getItem('email'),
    membership: 'Gold Member',
  };

  return (
    <Box style={{ position: 'relative' }}>
      <HamburgerMenu />
      <Box p={2}>
        <Typography variant="h6" mb={2}>Profile</Typography>
        <Typography variant="subtitle1">Name: {userProfile.name}</Typography>
        <Typography variant="subtitle1">Email: {userProfile.email}</Typography>
        <Typography variant="subtitle1" mb={2}>Membership: {userProfile.membership}</Typography>
        <Divider />
        <Box mt={2}>
          <Button variant="contained" fullWidth>Edit Profile</Button>
          <Button variant="outlined" fullWidth color="secondary" style={{ marginTop: '10px' }} onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ProfilePage;
