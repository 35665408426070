import React from 'react';
import { Box, Typography, Button } from '@mui/material';

function CategorySettings() {
  return (
    <Box p={3}>
      <Typography variant="h4" mb={2}>Manage Category</Typography>
      {/* Add functionality to manage category */}
      <Button variant="contained" color="primary">Add New Category</Button>
    </Box>
  );
}

export default CategorySettings;
