import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function SettingsMain() {
  const navigate = useNavigate();

  return (
    <Box>
      <h2>Tables Settings</h2>
      <Button variant="contained" onClick={() => navigate('/settings/tables')}>Tables Settings</Button>
      <Button variant="contained" onClick={() => navigate('/settings/users')}>Users Settings</Button>
      <Button variant="contained" onClick={() => navigate('/settings/categories')}>Categories Settings</Button>
      <Button variant="contained" onClick={() => navigate('/settings/products')}>Products Settings</Button>
      <Button variant="contained" onClick={() => navigate('/settings/payment-methods')}>Payment Methods Settings</Button>
      <Button variant="contained" onClick={() => navigate('/settings/tax-types')}>Tax Type Settings</Button>
      <Button variant="contained" onClick={() => navigate('/settings/specifications')}>Specification Settings</Button>
      <Button variant="contained" onClick={() => navigate('/settings/printers')}>Printer Settings</Button>
      <Button variant="contained" onClick={() => navigate('/settings/webhooks')}>Webhook Settings</Button>
      <Button variant="contained" onClick={() => navigate('/settings/general')}>General Settings</Button>
      <Button variant="contained" onClick={() => navigate('/settings/taxes')}>Taxes Settings</Button>
    </Box>
  );
}

export default SettingsMain;
