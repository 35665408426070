import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Typography, Tabs, Tab, BottomNavigation, BottomNavigationAction, CircularProgress } from '@mui/material';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ReportIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const TableOrderScreen = () => {
  const [vendorId, setVendorId] = useState(localStorage.getItem('vendor_id'));
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [selectedOrderType, setSelectedOrderType] = useState('Dine-In');
  const [locations, setLocations] = useState([]);
  const [tables, setTables] = useState([]);
  const [occupiedTables, setOccupiedTables] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedTable, setSelectedTable] = useState(null);
  const [navigationValue, setNavigationValue] = useState(0);
  const tabRefs = useRef([]); // Create ref for Tabs
  const [selectedLocation, setSelectedLocation] = useState(''); // Default selected location

  // Fetch locations on load
  useEffect(() => {
    if (vendorId && token) {
      setLoading(true);
      axios.get(`${process.env.REACT_APP_API_URL}/Pos/Table/Location/${vendorId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          setLocations(response.data);
          setSelectedLocation(response.data[0]?.location); // Default to the first location
        })
        .catch((error) => console.error('Error fetching locations:', error))
        .finally(() => setLoading(false));
    }
  }, [vendorId, token]);

  // Fetch tables by location whenever selectedLocation changes
  useEffect(() => {
    if (selectedLocation && vendorId && token) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/Pos/Table/ByLocation/${vendorId}/${selectedLocation}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => setTables(response.data))
        .catch((error) => console.error('Error fetching tables:', error))
        .finally(() => setLoading(false));
    }
  }, [selectedLocation, vendorId, token]);

  // Mark a table as occupied
  const markTableAsOccupied = (tableId, tableName) => {
    axios.put(`${process.env.REACT_APP_API_URL}/Table/Update/${vendorId}/${tableId}`, { status: 'occupied' }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        setOccupiedTables([...occupiedTables, tableId]); // Add to occupied tables
        navigate(`/pos/order?table=${tableId}&tableName=${encodeURIComponent(tableName)}&orderType=${selectedOrderType}`);
      })
      .catch((error) => console.error('Error marking table as occupied:', error));
  };

  // Handle table selection
  const handleTableSelect = (table) => {
    markTableAsOccupied(table.id, table.table_name);
  };

  // Handle order type selection
  const handleOrderTypeSelect = (type) => {
    setSelectedOrderType(type);
    if (type === 'Take Away') {
      navigate(`/pos/order?orderType=TakeAway`);
    }
  };

  const handleLocationChange = (newValue) => {
    setSelectedLocation(locations[newValue]?.location);

    // Scroll the selected tab into view
    const selectedTab = tabRefs.current[newValue];
    // console.log("Selected Tab Ref:", selectedTab); // Debugging
    if (selectedTab) {
      selectedTab.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  };

  // Scroll to selected tab after it's set
  useEffect(() => {
    if (locations.length > 0) {
      const selectedTabIndex = locations.findIndex((loc) => loc.location === selectedLocation);
      if (selectedTabIndex !== -1 && tabRefs.current[selectedTabIndex]) {
        // Scroll the selected tab into view
        const selectedTab = tabRefs.current[selectedTabIndex];
        // console.log("Scrolling to Tab:", selectedTab); // Debugging
        if (selectedTab) {
          selectedTab.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
      }
    }
  }, [selectedLocation, locations]);

  return (
    <Box p={2} style={{ minHeight: '100vh' }}>
      <Typography variant="h4" mb={3} align="center">
        POS - Table Orders
      </Typography>

      {/* Order Type Section */}
      <Box display="flex" justifyContent="center" mb={4}>
        {['Dine-In', 'Take Away'].map((type) => (
          <Button
            key={type}
            variant={selectedOrderType === type ? 'contained' : 'outlined'}
            onClick={() => handleOrderTypeSelect(type)}
            style={{
              width: '120px',
              height: '50px',
              margin: '0 10px',
              fontSize: '16px',
            }}
          >
            {type}
          </Button>
        ))}
      </Box>

      {/* Table Location Section */}
      {loading ? (
        <CircularProgress />
      ) : (
        <Tabs
          value={locations.findIndex((loc) => loc.location === selectedLocation)}
          onChange={(e, newValue) => handleLocationChange(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          style={{ marginBottom: '20px' }}
        >
          {locations.map((location, index) => (
            <Tab
              key={location.location}
              label={location.location}
              ref={(el) => (tabRefs.current[index] = el)} // Assign ref to each tab
              sx={{
                backgroundColor: selectedLocation === location.location ? 'lightblue' : '#fff',
                color: selectedLocation === location.location ? '#fff' : '#000',
                borderRadius: '10px',
                margin: '0 5px',
                transition: 'all 0.3s ease',
              }}
            />
          ))}
        </Tabs>
      )}

      {/* Table List Section */}
      <Box display="flex" flexWrap="wrap" justifyContent="center" mt={3} gap={3}>
        {tables.map((table) => (
          <Box key={table.id} style={{ margin: '10px', textAlign: 'center' }}>
            <Button
              variant={selectedTable === table.id ? 'contained' : 'outlined'}
              color={occupiedTables.includes(table.id) ? 'secondary' : 'primary'}
              onClick={() => handleTableSelect(table)}
              style={{
                width: '120px',
                height: '120px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '16px',
              }}
            >
              {table.table_name}
            </Button>
            {occupiedTables.includes(table.id) && (
              <Button
                size="small"
                variant="contained"
                color="info"
                onClick={() => navigate(`/pos/order?table=${table.id}`)}
                style={{ marginTop: '10px', width: '120px' }}
              >
                Add Order
              </Button>
            )}
          </Box>
        ))}
      </Box>

      {/* Bottom Navigation */}
      <BottomNavigation
        value={navigationValue}
        onChange={(event, newValue) => setNavigationValue(newValue)}
        showLabels
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#fff',
          borderTop: '1px solid #ddd',
          boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
        }}
      >
        <BottomNavigationAction label="POS" icon={<RestaurantIcon />} onClick={() => navigate('/pos/table')} />
        <BottomNavigationAction label="Orders" icon={<ListAltIcon />} onClick={() => navigate('/pos/orders')} />
        <BottomNavigationAction label="Reports" icon={<ReportIcon />} onClick={() => navigate('/pos/reports')} />
        <BottomNavigationAction label="Settings" icon={<SettingsIcon />} onClick={() => navigate('/pos/settings')} />
      </BottomNavigation>
    </Box>
  );
};

export default TableOrderScreen;
