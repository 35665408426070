import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Button, Typography, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

function TopNavigation({ vendorId, onSearch }) {
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  const navigate = useNavigate();

  const [supportsOrderType, setSupportsOrderType] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const fetchSettings = async () => {
      console.log({ sss: `${process.env.REACT_APP_API_URL}/Setting/List/${vendorId}` })
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/Setting/List/${vendorId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const settings = await response.json();
        console.log({ settings })
        if (settings && settings.message) {
          // Clear localStorage
          localStorage.clear();
          // Redirect to default / login
          navigate('/');
        } else {
          // get from settings
          setSupportsOrderType(settings.supportsOrderType === 1);
        }

      } catch (error) {
        console.error('Failed to fetch settings:', error);
      }
    };

    fetchSettings();
  }, [vendorId]);

  return (
    <AppBar position="static" style={{ backgroundColor: '#f8f9fa', boxShadow: 'none' }}>
      <Toolbar>
        <InputBase
          placeholder="Search for products..."
          value={searchTerm}
          onChange={handleSearchInputChange}
          style={{ backgroundColor: '#f1f1f1', padding: '5px 10px', borderRadius: '4px', marginRight: '10px', width: '100%' }}
        />
        <IconButton color="primary" onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
        {supportsOrderType && (
          <>
            <Button variant="outlined" style={{ marginRight: '10px', color: '#000' }}>Dine-In</Button>
            <Button variant="contained" color="primary">Take-Away</Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default TopNavigation;
