import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Button, Divider } from '@mui/material';
import BottomNavigationBar from './BottomNavigationBar';
import IconButton from '@mui/material/IconButton';
import HamburgerMenu from './HamburgerMenu';  // Import HamburgerMenu component

function ReceiptPage() {
  const [orderDetail, setOrderDetail] = useState(null);
  const [vendorDetail, setVendorDetail] = useState(null);
  const [vendorTaxesDetail, setVendorTaxesDetail] = useState(null);
  const [token] = useState(localStorage.getItem('token'));
  const [vendorId] = useState(localStorage.getItem('vendor_id'));
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get('orderId');
  const [userId] = useState(localStorage.getItem('user_id'));
  const [userName] = useState(localStorage.getItem('username'));

  useEffect(() => {
    if (orderId && vendorId) {
      loadReceipt(orderId);
    }
  }, [orderId, vendorId]);

  const loadReceipt = async (orderId) => {
    try {
      const orderResponse = await fetch(`${process.env.REACT_APP_API_URL}/Pos/Order/Detail/${vendorId}/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const order = await orderResponse.json();
      const taxes = await fetchVendorTaxes(vendorId);

      setOrderDetail(order);
      setVendorTaxesDetail(taxes);
    } catch (error) {
      console.error('Error loading order details:', error);
    }
  };

  const fetchVendorTaxes = async (vendorId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/VendorTaxes/WithTaxTypes/${vendorId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return await response.json();
  };

  const printReceipt = async () => {
    try {

      const vendorResponse = await fetch(`${process.env.REACT_APP_API_URL}/Vendor/ByID/${vendorId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const vendor = await vendorResponse.json();
      setVendorDetail(vendor);

      // Determine the event
      let event = orderDetail.status === 'pending' ? 'order_placed' : orderDetail.status === 'completed' ? 'order_completed' : 'order_paid';

      // Fetch printers
      const printersResponse = await fetch(`${process.env.REACT_APP_API_URL}/Printer/Active/${vendorId}/${event}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const printers = await printersResponse.json();

      if (printers.length <= 0) {
        alert('No printer configured.');
        return;
      }

      for (const printer of printers) {
        let content = null;
        if (printer.location == 'kitchen' && event == 'order_placed') {
          content = await generateKitchenReceiptContent(orderDetail, printer);
        } else if (printer.location == 'cashier' && event == 'order_placed') {
          content = await generatePlaceOrderReceiptContent(orderDetail, vendorDetail, printer);
        } else {
          content = await generateReceiptContent(orderDetail, vendorDetail, vendorTaxesDetail, printer);
        }
        sendToPrinter(printer, content);
      }
    } catch (error) {
      console.error('Error fetching order details for receipt:', error);
      alert('Failed to print receipt.');
    }
  };

  const sendToPrinter = async (printer, content) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/Printer/PrintReceipt/${vendorId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ printer, content }),
      });

      const result = await response.json();
      if (result.success) {
        alert(`Printer at ${printer.location} | Receipt printed successfully`);
      } else {
        alert(`Printer at ${printer.location} | ${result.message}`);
      }
    } catch (error) {
      console.error('Error sending receipt to printer:', error);
    }
  };

  const generateReceiptContent = async (orderDetail, vendorDetail, vendorTaxesDetail, printer) => {
    let content = `
      Order ID: ${orderDetail.id}\n
      Date: ${new Date(orderDetail.created_at).toLocaleDateString()}\n
      =====================\n
      Items:\n
    `;

    orderDetail.items.forEach(item => {
      content += `${item.quantity}x ${item.name} - RM${Number(item.total_price).toFixed(2)}\n`;
    });

    content += `
      =====================\n
      Subtotal: RM${Number(orderDetail.amount_to_pay).toFixed(2)}\n
      Total Taxes: RM${Number(orderDetail.total_taxes).toFixed(2)}\n
      Rounding: RM${Number(orderDetail.rounding_value).toFixed(2)}\n
      Receivable: RM${Number(orderDetail.rounded_receivable).toFixed(2)}\n
      Received: RM${Number(orderDetail.received_amount).toFixed(2)}\n
      Change: RM${Number(orderDetail.change).toFixed(2)}\n
    `;

    return content;
  };

  async function generatePlaceOrderReceiptContent(orderDetail, vendorDetail, printer) {

    let content = '<BOLD>Employee: {{employee}}</BOLD><BR><BOLD>POS: {{pos}}</BOLD><BR>--------------------------------<BR><BOLD>{{orderType}}</BOLD><BR><BOLD>{{orderTable}}</BOLD><BR>--------------------------------<BR>{{items}}--------------------------------<BR>{{endLine}}';
    const orderType = orderDetail.order_type || 'Items';
    const orderTable = orderDetail.table ? `Table: ${orderDetail.table.table_name}` : `Order ID: ${orderDetail.id}`;
    const charLength = printer.character_length || 32;

    let itemsContent = '';
    orderDetail.items.forEach(item => {
      const parsedItems = JSON.parse(item.items);
      const addonDetails = parsedItems.addonDetails || [];
      const toppingDetails = parsedItems.toppingDetails || [];
      const specifications = parsedItems.specifications || [];

      const itemLineName = LR(`${item.quantity}x ${item.name}`, `MYR${item.total_price}`, charLength);
      itemsContent += `<BOLD>${itemLineName}</BOLD><BR>`;

      if (addonDetails.length > 0) {
        addonDetails.forEach(addon => {
          itemsContent += `+ ${addon.name}<BR>`;
        });
      }
      if (toppingDetails.length > 0) {
        toppingDetails.forEach(topping => {
          itemsContent += `+ ${topping.name}<BR>`;
        });
      }
      if (specifications.length > 0) {
        specifications.forEach(spec => {
          itemsContent += `+ ${spec.option_name} (${spec.extra_cost > 0 ? `+ RM${Number(spec.extra_cost).toFixed(2)}` : 'No extra cost'})<BR>`;
        });
      }
    });

    const currentDateTime = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
    const endLine = LR(currentDateTime, `#${orderId}`, charLength);

    const printDetail = {
      orderId: orderDetail.id,
      totalAmount: orderDetail.amount_to_pay,
      orderTable,
      employee: userId,
      pos: userName,
      orderType,
      items: itemsContent,
      endLine
    };

    for (const [key, value] of Object.entries(printDetail)) {
      content = replaceKeyValue(content, key, value);
    }

    return content;
  }

  async function generateKitchenReceiptContent(orderDetail, printer) {

    let content = '<BOLD>{{orderTable}}</BOLD><BR>{{items}}';
    const orderType = orderDetail.order_type || 'Items';
    const orderTable = orderDetail.table ? orderDetail.table.table_name : orderDetail.id;
    const charLength = printer.character_length || 32;

    let itemsContent = '';
    orderDetail.items.forEach(item => {
      const parsedItems = JSON.parse(item.items);
      const addonDetails = parsedItems.addonDetails || [];
      const toppingDetails = parsedItems.toppingDetails || [];
      const specifications = parsedItems.specifications || [];

      const itemLineName = `${item.quantity} x ${item.name}`;
      itemsContent += `<BOLD>${itemLineName}</BOLD><BR>`;

      if (addonDetails.length > 0) {
        addonDetails.forEach(addon => {
          itemsContent += `+ ${addon.name}<BR>`;
        });
      }

      if (toppingDetails.length > 0) {
        toppingDetails.forEach(topping => {
          itemsContent += `+ ${topping.name}<BR>`;
        });
      }

      if (specifications.length > 0) {
        specifications.forEach(spec => {
          itemsContent += `+ ${spec.option_name} (${spec.extra_cost > 0 ? `+ RM${Number(spec.extra_cost).toFixed(2)}` : 'No extra cost'})<BR>`;
        });
      }

      itemsContent += `<CUT>`;
    });

    const printDetail = {
      orderId: orderDetail.id,
      orderTable,
      items: itemsContent
    };

    for (const [key, value] of Object.entries(printDetail)) {
      content = replaceKeyValue(content, key, value);
    }

    return content;
  }

  async function fetchSelectedTemplate(vendorId) {
    const response = await fetch(`${window.env.API_URL}/Setting/SelectedTemplate/${vendorId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const template = await response.json();
    return template;
  }

  function replaceKeyValue(content, key, value) {
    const regex = new RegExp(`{{${key}}}`, 'g');
    return content.replace(regex, value);
  }

  function LR(strLeft, strRight, length) {
    const strLeftLength = byteLength(strLeft, 'utf-8');
    const strRightLength = byteLength(strRight, 'utf-8');
    const totalLength = length - (strLeftLength + strRightLength);

    const spaces = ' '.repeat(totalLength);

    return strLeft + spaces + strRight;
  }

  function byteLength(str) {
    let s = str.length;
    for (let i = str.length - 1; i >= 0; i--) {
      const code = str.charCodeAt(i);
      if (code > 0x7f && code <= 0x7ff) s++;
      else if (code > 0x7ff && code <= 0xffff) s += 2;
      if (code >= 0xDC00 && code <= 0xDFFF) i--;
    }
    return s;
  }

  const customRound = (value) => {
    const fractionPart = value - Math.floor(value);
    const decimalPart = (fractionPart % 0.1).toFixed(2);
    if (decimalPart < 0.05) {
      return Math.floor(value * 20) / 20;
    } else {
      return Math.ceil(value * 20) / 20;
    }
  };

  if (!orderDetail) {
    return <div>Loading receipt...</div>;
  }

  return (
    <Box style={{ position: 'relative' }} p={2}>
      <IconButton onClick={() => window.history.back()}>
        <span>&larr;</span>
      </IconButton>
      <HamburgerMenu />

      <Box p={2} sx={{ fontFamily: "'Courier New', monospace", width: '320px', margin: '0 auto', textAlign: 'center', backgroundColor: '#fff', padding: '20px', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)', borderRadius: '10px' }}>


        <Typography variant="h5" sx={{ marginBottom: '10px' }}>
          Receipt
        </Typography>

        <Divider sx={{ marginBottom: '20px' }} />

        <Typography>
          Order ID: {orderDetail.id}
        </Typography>
        <Typography>
          Date: {new Date(orderDetail.created_at).toLocaleDateString()}
        </Typography>

        <Divider sx={{ marginY: '20px' }} />

        <Typography variant="h6" sx={{ marginBottom: '10px' }}>
          Items
        </Typography>

        {orderDetail.items.map((item) => (
          <Box key={item.id} display="flex" justifyContent="space-between" sx={{ marginBottom: '8px' }}>
            <Typography>{item.quantity}x {item.name}</Typography>
            <Typography>RM {Number(item.total_price).toFixed(2)}</Typography>
          </Box>
        ))}

        <Divider sx={{ marginY: '20px' }} />

        <Box display="flex" justifyContent="space-between">
          <Typography>Subtotal:</Typography>
          <Typography>RM {Number(orderDetail.amount_to_pay).toFixed(2)}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography>Taxes:</Typography>
          <Typography>RM {Number(orderDetail.total_taxes).toFixed(2)}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography>Rounding:</Typography>
          <Typography>RM {Number(orderDetail.rounding_value).toFixed(2)}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography>Receivable:</Typography>
          <Typography>RM {Number(orderDetail.rounded_receivable).toFixed(2)}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography>Received:</Typography>
          <Typography>RM {Number(orderDetail.received_amount).toFixed(2)}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography>Change:</Typography>
          <Typography>RM {Number(orderDetail.change).toFixed(2)}</Typography>
        </Box>

        <Divider sx={{ marginY: '20px' }} />

        <Button variant="contained" color="primary" fullWidth onClick={printReceipt}>
          Print Receipt
        </Button>

        {/* <Box mt={2} style={{ position: 'fixed', bottom: 0, left: 0, width: '100%' }}>
          <BottomNavigationBar />
        </Box> */}
      </Box>
    </Box>
  );
}

export default ReceiptPage;
