import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, TextField, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, ToggleButtonGroup, ToggleButton, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const POSOrderScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [vendorId, setVendorId] = useState(localStorage.getItem('vendor_id'));
  const [token, setToken] = useState(localStorage.getItem('token'));

  const tableName = new URLSearchParams(location.search).get('tableName');
  const tableId = new URLSearchParams(location.search).get('table');
  const orderType = new URLSearchParams(location.search).get('orderType');
  const locationId = new URLSearchParams(location.search).get('location');

  const [categories, setCategories] = useState([]); // Fetch from API
  const [products, setProducts] = useState([]); // Fetch based on selected category
  const [searchQuery, setSearchQuery] = useState(''); // Search query
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [cartTotal, setCartTotal] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isCartOpen, setCartOpen] = useState(false); // State for cart dialog
  const [cartItems, setCartItems] = useState([]); // State to store cart items
  const [selectedFoodItem, setSelectedFoodItem] = useState(null);
  const [discount, setDiscount] = useState('');
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(false);

  const [selectedAddons, setSelectedAddons] = useState([]); // For add-ons
  const [selectedToppings, setSelectedToppings] = useState([]); // For toppings
  const [selectedSpecifications, setSelectedSpecifications] = useState({}); // For product specifications
  const [quantity, setQuantity] = useState(1); // Product quantity

  // Fetch categories from the API
  useEffect(() => {
    setLoadingCategories(true);
    axios.get(`${process.env.REACT_APP_API_URL}/Categorie/List/${vendorId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setCategories(response.data);
        if (response.data.length > 0) {
          setSelectedCategory(response.data[0].id); // Select the first category by default
        }
      })
      .catch((error) => console.error('Error fetching categories:', error))
      .finally(() => setLoadingCategories(false));
  }, [vendorId]);

  // Fetch products when a category is selected or search query is updated
  useEffect(() => {
    if (selectedCategory || searchQuery) {
      setLoadingProducts(true);

      const url = searchQuery
        ? `${process.env.REACT_APP_API_URL}/Product/Search/${vendorId}?search=${searchQuery}`
        : `${process.env.REACT_APP_API_URL}/Product/Category/${vendorId}/${selectedCategory}`;

      axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (searchQuery) {
            setProducts(response.data);
          } else {
            setProducts(response.data.products);
          }
        })
        .catch((error) => console.error('Error fetching products:', error))
        .finally(() => setLoadingProducts(false));
    }
  }, [selectedCategory, searchQuery, vendorId, token]);

  // Handle category click
  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    setSearchQuery('');
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle adding to cart
  const handleAddToCart = () => {
    const priceAfterDiscount = selectedFoodItem.price - (selectedFoodItem.price * parseFloat(discount || 0) / 100);

    // Calculate the total cost of selected add-ons, toppings, and specifications
    let extraCost = 0;

    // Calculate the total cost of add-ons
    selectedAddons.forEach((addon) => {
      const addonDetail = selectedFoodItem.add_ons.find((a) => a.name === addon);
      if (addonDetail) {
        extraCost += addonDetail.price;
      }
    });

    // Calculate the total cost of toppings
    selectedToppings.forEach((topping) => {
      const toppingDetail = selectedFoodItem.toppings.find((t) => t.name === topping);
      if (toppingDetail) {
        extraCost += toppingDetail.price;
      }
    });

    // Calculate the total cost of specifications
    Object.keys(selectedSpecifications).forEach((specId) => {
      const spec = selectedSpecifications[specId];
      if (spec) {
        extraCost += parseFloat(spec.extra_cost);
      }
    });

    const totalItemPrice = (priceAfterDiscount + extraCost) * quantity;

    const newItem = {
      name: selectedFoodItem.name,
      price: totalItemPrice,
      selectedAddons,
      selectedToppings,
      selectedSpecifications, // Now includes both option name and extra cost
      quantity,
    };

    setCartItems([...cartItems, newItem]); // Add the selected item to cart
    setCartTotal(cartTotal + totalItemPrice);
    setCartCount(cartCount + quantity);
    setDialogOpen(false); // Close the modal after adding to cart
  };

  useEffect(() => {
    console.log("Updated selectedAddons:", selectedAddons);
  }, [selectedAddons]);

  useEffect(() => {
    console.log("Updated selectedSpecifications:", selectedSpecifications);
  }, [selectedSpecifications]);

  // Open product details dialog for the selected food item
  const openDialogForFoodItem = (foodItem) => {
    axios.get(`${process.env.REACT_APP_API_URL}/Product/Specifications/${vendorId}/${foodItem.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setSelectedFoodItem({
          ...foodItem,
          specifications: response.data.specifications,
          add_ons: JSON.parse(foodItem.add_ons),
          toppings: JSON.parse(foodItem.toppings),
          add_ons_type: foodItem.add_ons_type,
          toppings_type: foodItem.toppings_type,
        });
        setSelectedAddons([]); // Reset selected addons
        setSelectedToppings([]); // Reset selected toppings
        setSelectedSpecifications({}); // Reset selected specifications
        setQuantity(1); // Reset quantity
        setDialogOpen(true);
      })
      .catch((error) => console.error('Error fetching product details:', error));
  };

  const openCartDialog = () => {
    setCartOpen(true); // Open the cart dialog
  };

  const closeCartDialog = () => {
    setCartOpen(false); // Close the cart dialog
  };

  const handleSpecificationChange = (specId, optionId) => {
    const specification = selectedFoodItem.specifications.find((spec) => spec.id === parseInt(specId));
    const option = specification?.options.find((opt) => opt.option_id === optionId);

    if (option) {
      setSelectedSpecifications((prev) => ({
        ...prev,
        [specId]: {
          option_name: option.option_name,
          extra_cost: option.extra_cost
        }
      }));
    }
  };

  // Handle add-ons selection
  const handleAddonChange = (addonName) => {
    if (selectedFoodItem.add_ons_type === 'checkbox') {
      setSelectedAddons(() => {
        return addonName
      });
    } else {
      // For radio type, only one selection is allowed
      setSelectedAddons([addonName]);
    }
  };

  const handleToppingChange = (toppingName) => {
    if (selectedFoodItem.toppings_type === 'checkbox') {
      setSelectedToppings(() => {
        return toppingName;
      });
    } else {
      setSelectedToppings([toppingName]);
    }
  };

  const handleQuantityChange = (action) => {
    if (action === 'increment') {
      setQuantity(quantity + 1);
    } else if (action === 'decrement' && quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  // Handle placing the order
  const handlePlaceOrder = () => {
    const orderDetails = {
      tableId,
      tableName,
      orderType,
      cartItems,
      total: cartTotal,
    };

    // Send the order details to the backend or process as needed
    axios.post(`${process.env.REACT_APP_API_URL}/order/place`, orderDetails, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        navigate('/pos/table', { state: { table: tableId } });
      })
      .catch((error) => console.error('Error placing order:', error));
  };

  return (
    <Box p={2}>
      {/* Header with back and table info */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Button variant="text" onClick={() => navigate(-1)}>{'< Back'}</Button>
        <Typography variant="h6">Table: {tableName}</Typography>
      </Box>

      {/* Search and Filter */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <TextField
          variant="outlined"
          placeholder="Search item"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange} // Handle search input change
          style={{ width: '85%' }}
        />
        <IconButton>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* Main Category List */}
      {loadingCategories ? (
        <CircularProgress />
      ) : (
        <Box display="flex" height="100%" mb={2}>
          <Box width="25%" p={2} style={{ borderRight: '1px solid #ddd' }}>
            {categories.map((category) => (
              <Box key={category.id} mb={2}>
                <Typography
                  variant="h6"
                  style={{
                    cursor: 'pointer',
                    fontWeight: selectedCategory === category.id ? 'bold' : 'normal',
                    color: selectedCategory === category.id ? '#1976d2' : '#000',
                  }}
                  onClick={() => handleCategoryClick(category.id)}
                >
                  {category.name}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* Food List */}
          <Box width="75%" p={2}>
            {loadingProducts ? (
              <CircularProgress />
            ) : (
              <Grid container spacing={2}>
                {products.map((item) => (
                  <Grid item xs={6} key={item.id}>
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => openDialogForFoodItem(item)} // Open the dialog for food variants
                    >
                      {item.name} - RM {Number(item.price).toFixed(2)}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Box>
      )}

      {/* Addon Modal for Variants */}
      <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)} fullWidth>
        <DialogTitle>Choose Addons for {selectedFoodItem?.name}</DialogTitle>
        <DialogContent>
          {/* Addons */}
          {selectedFoodItem?.add_ons?.length > 0 && (
            <Box mb={2}>
              <Typography variant="body2" gutterBottom>Add-ons</Typography>
              <ToggleButtonGroup
                value={selectedAddons}
                onChange={(e, value) => handleAddonChange(value)} // Handle add-on change
                fullWidth
                exclusive={selectedFoodItem.add_ons_type === 'radio'}
              >
                {selectedFoodItem.add_ons.map((addon, index) => (
                  <ToggleButton key={index} value={addon.name}>
                    {addon.name} {addon.price > 0 ? `(+RM${addon.price})` : ''}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
          )}

          {/* Toppings */}
          {selectedFoodItem?.toppings?.length > 0 && (
            <Box mb={2}>
              <Typography variant="body2" gutterBottom>Toppings</Typography>
              <ToggleButtonGroup
                value={selectedToppings}
                exclusive={selectedFoodItem.toppings_type === 'radio'}
                onChange={(e, value) => handleToppingChange(value)}
                fullWidth
              >
                {selectedFoodItem.toppings.map((topping, index) => (
                  <ToggleButton key={index} value={topping.name}>
                    {topping.name} {topping.price > 0 ? `(+RM${topping.price})` : ''}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
          )}

          {/* Specifications */}
          {selectedFoodItem?.specifications?.map((specification) => (
            <Box key={specification.id} mb={2}>
              <Typography variant="body2" gutterBottom>{specification.name}</Typography>
              <ToggleButtonGroup
                value={selectedSpecifications[specification.id] || ''}
                exclusive={specification.input_type === 'radio'}
                onChange={(e, value) => handleSpecificationChange(specification.id, value)}
                fullWidth
              >
                {specification.options.map((option) => (
                  <ToggleButton key={option.option_id} value={option.option_id}>
                    {option.option_name} {option.extra_cost > 0 ? `(+RM${option.extra_cost})` : ''}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
          ))}

          {/* Quantity Selector */}
          <Box display="flex" alignItems="center" mt={2}>
            <Button variant="outlined" onClick={() => handleQuantityChange('decrement')}>-</Button>
            <Typography variant="body2" mx={2}>{quantity}</Typography>
            <Button variant="outlined" onClick={() => handleQuantityChange('increment')}>+</Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleAddToCart}>Add to Cart</Button>
        </DialogActions>
      </Dialog>

      {/* Cart Modal */}
      <Dialog open={isCartOpen} onClose={closeCartDialog} fullWidth>
        <DialogTitle>Cart</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {cartItems.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Box display="flex" justifyContent="space-between">
                  <Typography>{item.name}</Typography>
                  <Typography>{Number(item.price).toFixed(2)}</Typography>
                </Box>
                {item.selectedAddons.length > 0 && (
                  <Typography variant="body2">Addons: {item.selectedAddons.join(', ')}</Typography>
                )}
                {item.selectedToppings.length > 0 && (
                  <Typography variant="body2">Toppings: {item.selectedToppings.join(', ')}</Typography>
                )}
                {item.selectedSpecifications && (
                  <Typography variant="body2">Specifications: {Object.keys(item.selectedSpecifications).map(specId => (
                    <span key={specId}>{item.selectedSpecifications[specId]}, </span>
                  ))}</Typography>
                )}
                <Typography variant="body2">Quantity: {item.quantity}</Typography>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCartDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Cart and Place Order */}
      <Box display="flex" justifyContent="space-between" alignItems="center" p={2} style={{ backgroundColor: '#f0f0f0', position: 'fixed', bottom: 0, width: '100%' }}>
        <Button startIcon={<ShoppingCartIcon />} size="large" onClick={openCartDialog}>
          Cart ({cartCount})
        </Button>
        <Typography variant="h6">
          Total: RM{Number(cartTotal).toFixed(2)}
        </Typography>
        <Button variant="contained" color="primary" onClick={handlePlaceOrder}>
          Place Order
        </Button>
      </Box>
    </Box>
  );
};

export default POSOrderScreen;
