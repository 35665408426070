import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ProductList from './components/ProductList';
import ProductDetail from './components/ProductDetail';
import CartPage from './components/CartPage';
import TablePage from './components/TablePage';
import ProfilePage from './components/ProfilePage';
import CheckoutPage from './components/CheckoutPage';
import OrderPage from './components/OrderPage';
import PrintReceiptPage from './components/PrintReceiptPage';
import LoginPage from './components/LoginPage';
import OrderDetail from './components/OrderDetail';
import CustomerProductList from './components/CustomerProductList';
import CustomerCartPage from './components/CustomerCartPage';
import CustomerOrderPage from './components/CustomerOrderPage';
import CustomerOrderSummary from './components/CustomerOrderSummary';
import CustomerProductDetail from './components/CustomerProductDetail';
import Logout from './components/Logout'; // Import the new Logout component
import InitialPage from './components/InitialPage'; // Import the InitialPage

import SettingsMain from './components/settings/SettingsMain';
import TablesSettings from './components/settings/TablesSettings';
import UsersSettings from './components/settings/UsersSettings';
import CategorySettings from './components/settings/CategorySettings';
import ProductSettings from './components/settings/ProductSettings';
import PaymentMethodsSettings from './components/settings/PaymentMethodsSettings';
import TaxTypeSettings from './components/settings/TaxTypeSettings';
import SpecificationSettings from './components/settings/SpecificationSettings';
import PrinterSettings from './components/settings/PrinterSettings';
import WebhooksSettings from './components/settings/WebhooksSettings';
import GeneralSettings from './components/settings/GeneralSettings';
import TaxesSettings from './components/settings/TaxesSettings';

import TableOrderScreenMockup from './mockup/TableOrderScreen';
import POSOrderScreenMockup from './mockup/POSOrderScreen';

import TableOrderScreen from './pos/TableOrderScreen';
import POSOrderScreen from './pos/POSOrderScreen';
import POSLoginPage from './pos/LoginPage';

const generateNumericUUID = () => {
  const timestamp = Date.now();
  return timestamp.toString().slice(-6);
};

function PrivateRoute({ isAuthenticated, allowedRoles, children }) {
  const userRole = localStorage.getItem('role'); // Get the user role from localStorage

  // Check if the user is authenticated and has one of the allowed roles
  if (!isAuthenticated || (allowedRoles && !allowedRoles.includes(userRole))) {
    return <Navigate to="/login" />;
  }

  return children;
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  // Ensure a customerId is generated and saved in localStorage
  useEffect(() => {
    if (!localStorage.getItem('customerId')) {
      const customerId = generateNumericUUID();
      localStorage.setItem('customerId', customerId);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<InitialPage />} /> {/* Initial Page route */}

        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/login" element={!isAuthenticated ? <LoginPage setIsAuthenticated={setIsAuthenticated} /> : <Navigate to="/home" />} />
        <Route path="/logout" element={<Logout setIsAuthenticated={setIsAuthenticated} />} /> {/* Add the logout route */}
        <Route path="/home" element={<PrivateRoute isAuthenticated={isAuthenticated}><ProductList /></PrivateRoute>} />
        <Route path="/product/:vendorId/:productId" element={<PrivateRoute isAuthenticated={isAuthenticated}><ProductDetail /></PrivateRoute>} />
        <Route path="/cart" element={<PrivateRoute isAuthenticated={isAuthenticated}><CartPage /></PrivateRoute>} />
        <Route path="/table" element={<PrivateRoute isAuthenticated={isAuthenticated}><TablePage /></PrivateRoute>} />
        <Route path="/checkout" element={<PrivateRoute isAuthenticated={isAuthenticated}><CheckoutPage /></PrivateRoute>} />
        <Route path="/order" element={<PrivateRoute isAuthenticated={isAuthenticated}><OrderPage /></PrivateRoute>} />
        <Route path="/print-receipt" element={<PrivateRoute isAuthenticated={isAuthenticated}><PrintReceiptPage /></PrivateRoute>} />
        <Route path="/profile" element={<PrivateRoute isAuthenticated={isAuthenticated}><ProfilePage setIsAuthenticated={setIsAuthenticated} /></PrivateRoute>} />
        <Route path="/order-detail/:orderId" element={<PrivateRoute isAuthenticated={isAuthenticated}><OrderDetail /></PrivateRoute>} />

        {/* Customer routes */}
        <Route path="/customer/cart" element={<CustomerCartPage />} />
        <Route path="/customer/order" element={<CustomerOrderPage />} />
        <Route path="/customer/order-summary" element={<CustomerOrderSummary />} />
        <Route path="/customer/products" element={<CustomerProductList />} />
        <Route path="/customer/product/detail" element={<CustomerProductDetail />} />

        {/* Settings route with role-based access */}
        <Route
          path="/settings"
          element={
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              allowedRoles={['super_admin', 'admin', 'manager']}
            >
              <SettingsMain />
            </PrivateRoute>
          }
        />

        <Route path="/settings/tables" element={<PrivateRoute isAuthenticated={isAuthenticated}><TablesSettings /></PrivateRoute>} />
        <Route path="/settings/users" element={<PrivateRoute isAuthenticated={isAuthenticated}><UsersSettings /></PrivateRoute>} />
        <Route path="/settings/categories" element={<PrivateRoute isAuthenticated={isAuthenticated}><CategorySettings /></PrivateRoute>} />
        <Route path="/settings/products" element={<PrivateRoute isAuthenticated={isAuthenticated}><ProductSettings /></PrivateRoute>} />
        <Route path="/settings/payment-methods" element={<PrivateRoute isAuthenticated={isAuthenticated}><PaymentMethodsSettings /></PrivateRoute>} />
        <Route path="/settings/tax-types" element={<PrivateRoute isAuthenticated={isAuthenticated}><TaxTypeSettings /></PrivateRoute>} />
        <Route path="/settings/specifications" element={<PrivateRoute isAuthenticated={isAuthenticated}><SpecificationSettings /></PrivateRoute>} />
        <Route path="/settings/printers" element={<PrivateRoute isAuthenticated={isAuthenticated}><PrinterSettings /></PrivateRoute>} />
        <Route path="/settings/webhooks" element={<PrivateRoute isAuthenticated={isAuthenticated}><WebhooksSettings /></PrivateRoute>} />
        <Route path="/settings/general" element={<PrivateRoute isAuthenticated={isAuthenticated}><GeneralSettings /></PrivateRoute>} />
        <Route path="/settings/taxes" element={<PrivateRoute isAuthenticated={isAuthenticated}><TaxesSettings /></PrivateRoute>} />

        <Route path="/mockup/table" element={<TableOrderScreenMockup />} />
        <Route path="/mockup/order" element={<POSOrderScreenMockup />} />

        <Route path="/pos/table" element={<TableOrderScreen />} />
        <Route path="/pos/order" element={<POSOrderScreen />} />
        <Route path="/pos/login" element={!isAuthenticated ? <POSLoginPage setIsAuthenticated={setIsAuthenticated} /> : <Navigate to="/pos/table" />} />

      </Routes>
    </Router>
  );
}

export default App;
