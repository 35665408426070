import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Divider, IconButton, Button, Select, MenuItem } from '@mui/material';
import BottomNavigationBar from './BottomNavigationBar';
import { useNavigate } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu';  // Import HamburgerMenu component

function OrderPage() {
  const navigate = useNavigate();
  const vendorId = localStorage.getItem('vendor_id');  // Assuming vendorId is stored in localStorage
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState('all');  // Default to 'all' orders
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10); // Default limit
  const [totalPages, setTotalPages] = useState(1); // To handle pagination

  useEffect(() => {
    const fetchOrders = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`https://api.freegopos.com/Pos/History/${vendorId}?status=${status}&page=${page}&limit=${limit}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setOrders(data.orders || []);  // Assuming API returns an array of orders
        setTotalPages(Math.ceil(data.totalOrders / limit));  // Assuming API returns the total number of orders
      } catch (error) {
        console.error('Failed to fetch orders:', error);
      }
    };

    fetchOrders();
  }, [status, page, limit]);

  const handleOrderClick = (order) => {
    navigate(`/order-detail/${order.id}`); // Navigate to the detail page with the order ID
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setPage(1); // Reset to the first page when the status changes
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(prevPage => prevPage - 1);
    }
  };

  return (
    <Box style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box p={2} mt={0} style={{ flexGrow: 1 }}>
        <IconButton onClick={() => window.history.back()}><span>&larr;</span></IconButton>
        <HamburgerMenu />

        <Typography variant="h6" mb={2}>Your Orders</Typography>

        {/* Filter by Status */}
        <Box mb={2}>
          <Select value={status} onChange={handleStatusChange}>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
            <MenuItem value="paid">Paid</MenuItem>
            <MenuItem value="cancel">Cancelled</MenuItem>
            <MenuItem value="refund">Refund</MenuItem>
          </Select>
        </Box>

        {/* List of Orders */}
        <List>
          {orders.length > 0 ? (
            orders.map(order => (
              <React.Fragment key={order.id}>
                <ListItem button onClick={() => handleOrderClick(order)}>
                  <ListItemText
                    primary={`Order #${order.id} - ${new Date(order.created_at).toLocaleDateString()}`}
                    secondary={`Status: ${order.status} | Order Type: ${order.order_type}`}
                  />
                  <Typography variant="body2">RM {Number(order.amount_to_pay).toFixed(2)}</Typography>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))
          ) : (
            <Typography>No orders available.</Typography>
          )}
        </List>

        {/* Pagination Controls */}
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button onClick={handlePreviousPage} disabled={page === 1}>
            Previous
          </Button>
          <Typography>Page {page} of {totalPages}</Typography>
          <Button onClick={handleNextPage} disabled={page === totalPages}>
            Next
          </Button>
        </Box>
      </Box>

      {/* <Box style={{ position: 'sticky', bottom: 0, left: 0, width: '100%', backgroundColor: '#fff', boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)' }}>
        <BottomNavigationBar />
      </Box> */}
    </Box>
  );
}

export default OrderPage;
