import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ProductList from './ProductList';
import CartPage from './CartPage';

function CustomerOrderPage() {
  const [vendorId, setVendorId] = useState(null);
  const [tableId, setTableId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Parse vendorId and tableId from the URL query parameters
    const queryParams = new URLSearchParams(location.search);
    setVendorId(queryParams.get('vendorId'));
    setTableId(queryParams.get('tableId'));
  }, [location]);

  if (!vendorId || !tableId) {
    return <div>Error: Vendor or Table ID is missing from the URL!</div>;
  }

  return (
    <div>
      <h1>Welcome to Vendor #{vendorId}</h1>
      <p>Table #{tableId}</p>
      {/* Load the product list for the vendor */}
      <ProductList vendorId={vendorId} />
      {/* Cart display */}
      <CartPage vendorId={vendorId} tableId={tableId} />
    </div>
  );
}

export default CustomerOrderPage;
