import React from 'react';
import { Box, Typography, Button } from '@mui/material';

function UsersSettings() {
  return (
    <Box p={3}>
      <Typography variant="h4" mb={2}>Manage Users</Typography>
      {/* Add functionality to manage User */}
      <Button variant="contained" color="primary">Add New Users</Button>
    </Box>
  );
}

export default UsersSettings;
