import React from 'react';
import { Box, Typography, Button } from '@mui/material';

function GeneralSettings() {
  return (
    <Box p={3}>
      <Typography variant="h4" mb={2}>General Setting</Typography>
      {/* Add functionality to manage User */}
      <Button variant="contained" color="primary">Add New General Settings</Button>
    </Box>
  );
}

export default GeneralSettings;
