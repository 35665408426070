import React, { useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Badge } from '@mui/material';
import { Home, ShoppingCart, Receipt } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

function CustomerBottomNavigationBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [cartCount, setCartCount] = useState(0);
  const [customerId, setCustomerId] = useState(0);

  // Extract vendorId and tableId from the current URL
  const vendorId = new URLSearchParams(location.search).get('vendorId');
  const tableId = new URLSearchParams(location.search).get('tableId');

  useEffect(() => {

    console.log({ ss: localStorage.getItem('customerId') })
    setCustomerId(localStorage.getItem('customerId'));

    const updateCartCount = () => {
      const storedCartItems = JSON.parse(localStorage.getItem('cart_items')) || [];
      setCartCount(storedCartItems.length);
    };

    updateCartCount();

    // Listen for changes to localStorage
    window.addEventListener('storage', updateCartCount);

    return () => {
      window.removeEventListener('storage', updateCartCount);
    };
  }, []);

  return (
    <BottomNavigation
      showLabels
      style={{ backgroundColor: '#fff' }}
      onChange={(event, newValue) => {
        navigate(newValue);
      }}
    >
      {/* Passing vendorId and tableId as part of the URL */}
      <BottomNavigationAction
        label="Home"
        icon={<Home />}
        value={`/customer/products?vendorId=${vendorId}&tableId=${tableId}`}
      />
      <BottomNavigationAction
        label="Cart"
        icon={
          <Badge badgeContent={cartCount} color="secondary">
            <ShoppingCart />
          </Badge>
        }
        value={`/customer/cart?vendorId=${vendorId}&tableId=${tableId}`}
      />
      <BottomNavigationAction
        label="Order"
        icon={<Receipt />}
        value={`/customer/order-summary?vendorId=${vendorId}&tableId=${tableId}&customerId=${customerId}`}
      />
    </BottomNavigation>
  );
}

export default CustomerBottomNavigationBar;
