import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const InitialPage = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  const handleMockupRedirect = () => {
    navigate('/mockup/table');
  };

  const handlePosRedirect = () => {
    navigate('/pos/login');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Typography variant="h4" gutterBottom>
        Welcome to the freeGo
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={handleLoginRedirect}
        style={{ marginBottom: '20px', width: '200px' }}
      >
        Go to Login
      </Button>

      <Button
        variant="contained"
        color="secondary"
        onClick={handlePosRedirect}
        style={{ marginBottom: '20px', width: '200px' }}
      >
        Go to POS
      </Button>

      <Button
        variant="contained"
        color="warning"
        onClick={handleMockupRedirect}
        style={{ marginBottom: '20px', width: '200px' }}
      >
        Go to Mockup
      </Button>
    </Box>
  );
};

export default InitialPage;
