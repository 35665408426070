import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear localStorage or any authentication data
    localStorage.clear();

    // Set authentication status to false
    setIsAuthenticated(false);

    // Redirect to the login page
    navigate('/login');
  }, [navigate, setIsAuthenticated]);

  return null; // No UI needed for this component
};

export default Logout;
