import React from 'react';
import { Box, Typography, Button } from '@mui/material';

function PaymentMethodsSettings() {
  return (
    <Box p={3}>
      <Typography variant="h4" mb={2}>Manage Payment Methods</Typography>
      {/* Add functionality to manage category */}
      <Button variant="contained" color="primary">Add New Payment Methods</Button>
    </Box>
  );
}

export default PaymentMethodsSettings;
